import "./style.scss";
import Vision from "../../../../../assets/images/vision.png";
import Target from "../../../../../assets/images/target.png";
import Value from "../../../../../assets/images/benefit.png";

const ViMiVal = () => {
  return (
    <div className="about">
      <div className="wrap_vision">
        <div className="vision">
          <div className="hr-top"></div>
          <div className="vision_content">
            <div className="vision_content_left">
              <img src={Vision} alt="vision" />
              <div className="hr-bottom"></div>
            </div>
            <div className="vision_content_right">
              <h3>Vision</h3>
              <p>
                In today's interconnected world, the abundance and diversity of
                network resources offer boundless opportunities. By harnessing
                the vast resources of the online community and their electronic
                devices, we unlock invaluable products such as internet content
                and peer-to-peer data transmission. At DAK, we embrace
                Blockchain & AI technologies to maximize resource utilization,
                realizing their full potential.
                {/* <ul style={{ marginLeft: "16px" }}>
                  <li>
                    <span>Contents on the Internet.</span>
                  </li>

                  <li>
                    <span>
                      Capability to deal with the transmission of data within a
                      peer-to-peer network.
                    </span>
                  </li>
                </ul>
                */}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="wrap_mission">
        <div className="mission">
          <div className="hr-top"></div>
          <div className="mission_content">
            <div className="mission_content_left">
              <img src={Target} alt="target" />
              <div className="hr-bottom"></div>
            </div>
            <div className="mission_content_right">
              <h3>Mission</h3>
              <p>
                DAK's mission is to create a transparent and inclusive
                Integrated Social Network that fosters the "Electronic
                Integration Effect." Through our Blockchain & AI -powered
                platform, we aim to generate economic value for all community
                members. Additionally, we empower users by establishing a
                Decentralized Cloud Computing network, allowing them to regain
                control over their data.
                {/* <ul>
                  <li>
                    <span>
                      Create a more transparent and comprehensive Synthetic
                      Social Network by taking advantage of Blockchain and
                      reward mechanism based on each entity's contribution.
                    </span>
                  </li>
                  <li>
                    <span>
                      Reduce reliance on Centralized Cloud infrastructure by
                      establishing a Decentralized Cloud Computing network.
                    </span>
                  </li>
                </ul> */}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="wrap_value">
        <div className="value">
          <div className="hr-top"></div>
          <div className="value_content">
            <div className="value_content_left">
              <img src={Value} alt="value" />
              <div className="hr-bottom"></div>
            </div>
            <div className="value_content_right">
              <h3>Value</h3>
              <p>
                DAK upholds the principles of decentralization and immutability,
                utilizing these fundamental characteristics of the blockchain
                economic platform. We prioritize community connection and
                development in a fair and democratic manner. By providing
                innovative technology solutions, DAK empowers users to transform
                available resources into valuable tools, enabling them to reap
                well-deserved rewards. We are dedicated to making meaningful
                contributions to society's sustainable development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViMiVal;
