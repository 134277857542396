import React from "react";
import { useLocation } from "react-router";
import "./QA.scss";
import UserQA from "./UserQA";
const QA = () => {
  const location = useLocation();
  const userQuestions = [
    { title: "Products & Services", type: 1 },
    { title: "Security & Safety", type: 2 },
    { title: "Development plan & Partners", type: 3 },
    { title: "Monetization & Rewards", type: 4 },
  ];
  const userQuestionsKOL = [
    { title: "Earning potential and financial opportunities", type: 1 },
    { title: "Requirements and eligibility to participate", type: 2 },
    { title: "Withdrawing money and managing accounts", type: 3 },
    { title: "Community benefits", type: 4 },
  ];
  const questionAndanswer = [
    {
      type: 1,
      contents: [
        {
          question: "What products or services does the DAK project provide?",
          answer:
            "The DAK project provides a decentralized cloud computing ecosystem with a focus on delivering services related to infrastructure, blockchain, and AI. This ecosystem encompasses a range of products and services to support users in storing data, connecting and communicating, entertainment, and monetization",
          content_type: 1,
        },
        {
          question:
            "What are the prominent features of DAK that can optimize the user experience?",
          answer:
            "As a diverse ecosystem with multiple products and services, DAK offers several prominent features that optimize user experience, while creating favorable conditions for users to participate and leverage opportunities in the cryptocurrency and blockchain industry",
          content_type: 2,
        },
      ],
    },
    {
      type: 2,
      contents: [
        {
          question:
            "How does DAK ensure the security and safety of users' data and transactions?",
          answer:
            "To ensure the security and safety of users' data and transactions, DAK implements user authentication mechanisms, data encryption, two-factor authentication, network protection and continuous evaluation and monitoring DAK's system. The development team constantly updates knowledge to enhance the system's security",
          content_type: 1,
        },
        {
          question:
            "How does DAK implement policies and terms of use to protect users' rights?",
          answer:
            "To protect users' rights, DAK empowers community to determine desired policies and terms. DAO assists in proposing and implementing community policies and terms by making decisions based on the majority's Votes and utilizing automatic mechanisms to ensure transparency and prevent manipulation. This fosters an environment that upholds users' rights and creates a fair and reliable environment for all community members",
          content_type: 2,
        },
        {
          question:
            "What are the risks or limitations related to earning money on DAK that I should be aware of?",
          answer:
            "The cryptocurrency industry and internet space inherently carry numerous risks and cannot be entirely controlled. Users should carefully consider and understand the potential risks and limitations when operating within the DAK ecosystem",
          content_type: 3,
        },
      ],
    },
    {
      type: 3,
      contents: [
        {
          question:
            "What is the roadmap for the future development and improvement of DAK?",
          answer:
            "DAK has detailed development plans with a clear timeline for execution. The project has set goals, conducted market analysis, defined strategies, established specific plans and schedules, established measurable indicators, planned for monitoring and evaluation, and devised adjustment and improvement plans",
          content_type: 1,
        },
        {
          question:
            "Does DAK have plans to expand its market and development internationally?",
          answer:
            "DAK has a comprehensive plan for expanding the market and international development through supporting multiple languages on the platform and expanding the network of partners",
          content_type: 2,
        },
      ],
    },
    {
      type: 4,
      contents: [
        {
          question: "How does DAK offer earning opportunities for users?",
          answer:
            "The DAK ecosystem provides monetization opportunities for users through interactive activities and content creation on DAKSHOW, trading/leasing NFT fragments and contributing devices to the DCC and DAK Block network",
          content_type: 1,
        },
        {
          question:
            "What are the methods to earn money on DAK? Are there any specific requirements or conditions?",
          answer:
            "Users can monetize on DAK through various methods with specific requirements and conditions regulated by the community",
          content_type: 2,
        },
        {
          question:
            "How can I track and manage the earnings in my DAK account?",
          answer:
            "Users can actively, transparently, and auditably track their earnings and manage their accounts through DAKSHOW platform, their Crypto Wallet, and on-chain data",
          content_type: 3,
        },
      ],
    },
  ];
  const questionAndanswerKOL = [
    {
      type: 1,
      contents: [
        {
          question:
            "When participating in the DAK ecosystem, how much money can I earn and from which activities?",
          answer:
            "Depending on your personal interests and talents, you can choose suitable activities to optimize your experience and earnings within the DAK ecosystem. Income from activities on DAK or any other platform typically depends on various factors such as 'Number of followers,' 'Your engagement,' 'Quality of content,' 'Contracts and collaboration opportunities,' 'Market and target audience,' 'Platform policies and terms,' and more. To accurately estimate the amount of money you can earn from activities on DAK, it is advisable to thoroughly understand how the system operates, its policies, and conditions, and reach out to their support team for specific and detailed information",
          content_type: 1,
        },
        {
          question:
            "Does DAK provide any financial support or investment opportunities beyond earning money?",
          answer:
            "DAK has policies and regulations regarding providing financial support and other investment opportunities beyond direct earnings to enhance the growth and attractiveness for influencers and content creators while strengthening the development and sustainability of the DAK ecosystem",
          content_type: 2,
        },
      ],
    },
    {
      type: 2,
      contents: [
        {
          question:
            "What do I need to do to participate in DAK's earning program?",
          answer:
            "DAK has specific policies and regulations in place to create an attractive and sustainable environment, supporting and encouraging the development of influencers and content creators within the DAK ecosystem. These policies and regulations are proposed and determined by the DAK community itself. It is important for you to thoroughly understand and comply with the policies and terms to protect your rights and actively and effectively participate in the DAK community. Remember that registering and verifying your account will significantly increase the benefits you can enjoy",
          content_type: 1,
        },
      ],
    },
    {
      type: 3,
      contents: [
        {
          question:
            "Are there any conditions or constraints regarding withdrawing money or using the earnings obtained on DAK?",
          answer:
            "DAK has reasonable conditions and constraints regarding the withdrawal of funds or the utilization of earnings obtained from money-making activities within the DAK ecosystem. This is to ensure fairness, reliability, and security in the process of using DAK's services and to support the protection of the community and sustainable development for both the ecosystem and users",
          content_type: 1,
        },
        {
          question:
            "To track the earnings and manage my earning account, what tools and features will I use?",
          answer:
            "You can actively track your earnings and manage your account in a transparent and auditable manner through the DAKSHOW platform, your Crypto Wallet, and the blockchain data of the system",
          content_type: 2,
        },
      ],
    },
    {
      type: 4,
      contents: [
        {
          question:
            "What other benefits and value can I receive when participating in the DAK community?",
          answer:
            "Joining the DAK community as an influencer and content creator brings you numerous benefits and value, from earning potential and increased influence to content ownership and investment opportunities. You can showcase your talent, connect with others, and engage within this captivating community",
          content_type: 1,
        },
      ],
    },
  ];
  const detailAnswer = [
    {
      content_type: 1,
      type: 1,
      header:
        "The products and services provided by the DAK ecosystem include:",
      answers: [
        {
          title: "1. DAKSHOW - An integrated and decentralized social network:",
          answer:
            "managed by the community through DAO. DAKSHOW offers monetization programs and rewards for users, allowing them the opportunity to make money from investment activities and other interactive activities on the platform.",
        },
        {
          title: "2. Frag NFT - Investing and trading NFT fragments:",
          answer:
            "The protocol helps tokenize content and divide it into fragments as desired by the owner. These fragments can be bought, sold, exchanged, or rented based on the owner's preferences and market demand.",
        },
        {
          title: "3. AMR - Market evaluation and analysis:",
          answer:
            " A tool for automatically conducting extensive market research using AI, evaluating the quality of content interaction and content creators.",
        },
        {
          title: "4. DIMA - Decentralized Marketing:",
          answer:
            "A platform that optimally and efficiently connects advertisers, content creators, and advertising distributors directly.",
        },
        {
          title: "5. DCC - Decentralized Cloud Computing:",
          answer:
            " A distributed cloud computing network based on user-contributed devices, allowing contributors the opportunity to earn rewards from idle electronic devices.",
        },
        {
          title: "6. DAK Block - Hybrid chain:",
          answer:
            "A hybrid chain between Blockchain and Block Lattice, increasing transaction speed with low costs, transparency, and high security.",
        },
      ],
    },
    {
      content_type: 2,
      type: 1,
      header:
        "The prominent features of the DAK ecosystem that can optimize the user experience are:",
      answers: [
        {
          title: "1. Aggregation:",
          answer:
            "DAKSHOW is an All-in-1 platform that allows users to view multiple contents from various popular social media networks simultaneously.",
        },
        {
          title: "2. Monetization:",
          answer:
            "With act-2-earn and con10nomy, DAKSHOW provides users with opportunities to make money by participating in platform activities and monetizing their content. The transparency of content co-ownership increases the economic benefits for co-owners in buying/selling or renting NFT fragments.",
        },
        {
          title: "3. Community-driven:",
          answer:
            "All community policies are decided through voting by members within the community via the DAO mechanism.",
        },
        {
          title: "4. Sociality:",
          answer:
            "AMR reflects the community/market's evaluation of content/topics/creators and provides suitable proposals aligned with the community's preferences and tastes.",
        },
        {
          title: "5. Connectivity:",
          answer:
            " DIMA diversifies content for advertising campaigns and directly reaches target customers through suitable influencers, content creators, and advertising distributors based on advertisers' requirements and budgets.",
        },
        {
          title: "6. Flexibility:",
          answer:
            "DCC allows users to contribute devices to the network, ensuring fast and secure processing of data. DAK Block facilitates quick, secure, and cost-effective transactions for users.",
        },
      ],
    },
    {
      content_type: 1,
      type: 2,
      header:
        "To ensure the security and safety of users' data and transactions, DAK implements the following measures:",
      answers: [
        {
          title: "1. User verification:",
          answer:
            "DAK applies KYC (Know Your Customer) to verify users, ensuring protection against potential negative scenarios.",
        },
        {
          title: "2. Data encryption:",
          answer:
            "DAK utilizes strong encryption technologies to safeguard users' data. This ensures that data is encrypted during transmission and storage within DAK's system, preventing unauthorized access from external parties.",
        },
        {
          title: "3. Two-factor authentication:",
          answer:
            "DAK requires users to use two-factor authentication, such as PIN codes or biometrics, to ensure high-level authentication when logging in or performing significant transactions.",
        },
        {
          title: "4. Network and system protection:",
          answer:
            " DAK invests significantly in network and system protection using advanced security measures, including firewalls, intrusion detection, and security management systems.",
        },
        {
          title: "5. Monitoring and surveillance:",
          answer:
            " DAK regularly checks and monitors activities on the system to detect and prevent invalid actions or security breaches.",
        },
        {
          title: "6. Staff training:",
          answer:
            "DAK provides regular security training for its team to enhance awareness of security threats and equip them to identify and respond to attacks effectively.",
        },
      ],
    },
    {
      content_type: 2,
      type: 2,
      header:
        "To protect users' rights, the DAO (Decentralized Autonomous Organization) follows these steps:",
      answers: [
        {
          title: "1. Proposal of policies and terms:",
          answer:
            "Community members can propose new policies and terms or changes existing ones. This can be done directly on DAKSHOW platform",
        },
        {
          title: "2. Discussion and voting:",
          answer:
            "The community will discuss and evaluate the proposed policies and terms, using voting systems and smart contracts to decide whether they should be accepted or not. The evaluation is based on the majority's opinion within the community.",
        },
        {
          title: "3. Implementation of policies and terms:",
          answer:
            "After the policies and terms are approved, the DAO will implement these decisions through smart contracts and other automated mechanisms. This ensures transparency and prevents manipulation of decisions.",
        },
        {
          title: "4. Monitoring and evaluation: ",
          answer:
            "The community and DAO will monitor the implementation of policies and terms to ensure fairness and appropriateness. If there are issues or complaints from users, the DAO can initiate new discussions to resolve the situation.",
        },
        {
          title: "5. Integrating user feedback:",
          answer:
            "The DAO will integrate user feedback into the process of proposing and implementing policies and terms. This helps to improve the quality of services and build trust within the community",
        },
      ],
    },
    {
      content_type: 3,
      type: 2,
      header:
        "When participating in monetize on DAK, users need to be aware of and understand the following risks and limitations:",
      answers: [
        {
          title: "1. Transaction risks:",
          answer:
            "Earning cryptocurrencies often involves transactions and the use of crypto wallets. Users should exercise caution when conducting transactions and carefully verify before transferring funds or engaging in any transactions on the platform",
        },
        {
          title: "2. Market volatility risk:",
          answer:
            "The value of cryptocurrencies tends to fluctuate significantly and cannot be predicted. This can reduce the value of the cryptocurrencies that users are earning. Users should consider and understand this risk before participating in cryptocurrency earnings.",
        },
        {
          title: "3. Risks of unreliable content:",
          answer:
            " There is a wide range of content on the platform, and not all of it is trustworthy. Users should conduct thorough research on the content before engaging in buying/selling or renting NFT fractions of content to avoid falling into fraudulent or low-quality content.",
        },
        {
          title: "4. Legal and regulatory limitations:",
          answer:
            "Earning cryptocurrencies may be subject to regulations and laws in each country or territory. Users need to research and comply with relevant regulations to avoid legal violations.",
        },
        {
          title: "5. Uncontrollable risks:",
          answer:
            "Users should remember that the cryptocurrency market is an environment that cannot be fully controlled. This means that investing and earning cryptocurrencies always come with inherent risks, and users should carefully assess and manage these risks.",
        },
      ],
    },
    {
      content_type: 1,
      type: 3,
      header: "DAK has a detailed development plans as follows:",
      answers: [
        {
          title: "1. Defined objectives:",
          answer:
            " DAK has clearly defined long-term and short-term development objectives. These objectives include key metrics such as revenue growth, market expansion, user acquisition, and product and service enhancements.",
        },
        {
          title: "2. Market research & Competitive analysis:",
          answer:
            "DAK has conducted market analysis to understand customer needs, evaluate competition, and identify development opportunities.",
        },
        {
          title: "3. Determined the strategy:",
          answer:
            "DAK has identified a development strategy to achieve the set objectives. This strategy includes elements such as product and service offerings, target markets, customer approach, marketing plans, and partnership collaborations.",
        },
        {
          title: "4. Planning and timelines:",
          answer:
            "DAK has devised detailed plans for the activities required to implement the strategy. These plans encompass research and development, marketing and advertising, building partnerships, product and service management, and customer support.",
        },
        {
          title: "5. Establishing performance metrics:",
          answer:
            "DAK has set up measurement indicators and criteria to evaluate progress and achieve results following the OKR model. These indicators relate to revenue, user count, customer feedback, and other financial metrics.",
        },
        {
          title: "6. Monitoring and evaluation:",
          answer:
            " DAK consistently monitors the progress of implementation according to the plan and evaluates the outcomes achieved.",
        },
        {
          title: "7. Adjusting and improving:",
          answer:
            "Based on monitoring and evaluation, DAK adjusts its plans and development activities when necessary. This ensures adaptability and optimization of operations to achieve development objectives.",
        },
      ],
    },
    {
      content_type: 2,
      type: 3,
      header:
        "Regarding international market expansion, DAK has developed a detailed plan with the following key aspects:",
      answers: [
        {
          title: "1. Product development:",
          answer:
            " DAK products support multiple languages worldwide, ensuring competitiveness and familiarity for users",
        },
        {
          title: "2. Market expansion:",
          answer:
            "DAK allocates 1/4 of capital to expand into new markets and enhance presence and user reach. This may involve expanding the sales and marketing teams, improving promotional efforts, and establishing local partnerships.",
        },
        {
          title: "3. Investment in marketing and advertising:",
          answer:
            "DAK invests 1/4 capital in marketing and advertising activities to increase visibility and attract new users. This may include online advertising costs, affiliate marketing campaigns, or other creative marketing initiatives.",
        },
        {
          title: "4. Building and maintaining infrastructure:",
          answer:
            "DAK invests in building and expanding its infrastructure, including server systems, security measures, data development and management, as well as technical support and customer service.",
        },
      ],
    },
    {
      content_type: 1,
      type: 4,
      header:
        "The DAK ecosystem provides monetization opportunities for users through the following mechanisms:",
      answers: [
        {
          title: "1. Act-2-Earn:",
          answer:
            "Users are rewarded with DAK Points for interactive activities on the DAKSHOW platform. DAK Points can be swap to DAK Tokens and other valuable items at a transparent and clearly defined exchange rate",
        },
        {
          title: "2. Con10nomy:",
          answer:
            " Users can create their own content or share content from others on DAKSHOW. When these contents receive feedback, likes, and votes from the community, users will be rewarded or share profits from DAKSHOW through advertising or other media activities. Additionally, user-created content can be divided into Frag NFTs, which can be sold or rented to make money.",
        },
        {
          title: "3. Advertising Content Creation:",
          answer:
            "DIMA connects users who are content creators suitable for the advertiser's requirements to produce advertising content. As a result, content creators will be paid according to the agreement between the two parties.",
        },
        {
          title: "4. Advertising Content Distribution:",
          answer:
            "DIMA connects users who are influencers with a group of followers that match the advertiser's target audience to distribute advertising content. As a result, advertising distributors will be paid according to the agreement between the two parties",
        },
        {
          title: "5. Device Contribution:",
          answer:
            "DCC and DAK Block are decentralized networks that require a significant number of devices to operate and maintain. Therefore, users who contribute devices to the network will significantly increase the processing capacity and scalability of the network. Consequently, users who contribute devices will receive a share of the network's profits.",
        },
        {
          title: "6. Staking:",
          answer:
            "Staking involves depositing and locking a certain amount of crypto on the DAK network to unlock higher privileges. Users will receive corresponding interest rates based on the amount staked. The interest rates are calculated using a transparent and clearly defined formula.",
        },
      ],
    },
    {
      content_type: 2,
      type: 4,
      header:
        "Methods to monetize on DAK with specific requirements and conditions:",
      answers: [
        {
          title: "1. Interact on DAKSHOW:",
          answer:
            " Users are required to register and verify their accounts to increase the benefits they receive",
        },
        {
          title: "2. Create content on DAKSHOW:",
          answer:
            " Users need to register and verify their accounts to increase the benefits they receive. To be eligible for rewards from content creation, users must verify Ownership Rights through DAKSHOW's Ownership Verification mechanism.",
        },
        {
          title: "3. Share content from other users on DAKSHOW:",
          answer:
            " Users are required to register and verify their accounts to increase the benefits they receive.",
        },
        {
          title: "4. Sell/Rent out their own content NFT fractions:",
          answer:
            "Users are required to register and verify their accounts to increase the benefits they receive. Only Active Members can fractionate NFT content after verify Ownership Rights.",
        },
        {
          title: "5. Create content for advertisers:",
          answer:
            "Users are required to register and verify their accounts to increase the benefits they receive. Users who are being proposed need to have an Interactive Sentiment Index, location, language, and pricing that match with the requirements of the advertisers.",
        },
        {
          title: "6. Distribute content for advertisers:",
          answer:
            "Users are required to register and verify their accounts to increase the benefits they receive. Users who are being proposed need to have an Interactive Sentiment Index, location, language, followers and pricing that match with the requirements of the advertisers.",
        },
        {
          title: "7. Activate the advertisements profit-sharing on DAKSHOW:",
          answer:
            "Users are required to register and verify their accounts to increase the benefits they receive. Only Active Members can activate this feature.",
        },
        {
          title: "8. Contribute equipment to the DCC and DAK Block networks:",
          answer:
            "Users are required to register and verify their accounts to increase the benefits they receive.",
        },
        {
          title: "9. Staking on the network and receive rewards:",
          answer:
            "Users are required to register and verify their accounts to increase the benefits they receive",
        },
      ],
    },
    {
      content_type: 3,
      type: 4,
      header:
        "Users can track their earnings and manage their earning accounts on DAKSHOW as follows:",
      answers: [
        {
          title: "1. Personal Wallet:",
          answer:
            "DAKSHOW provides users with a personal wallet where they can view information about their earning account, including the amount earned and the account balance",
        },
        {
          title: "2. Transaction History:",
          answer:
            "Users can access their transaction history, which displays all the transactions related to earning on the DAKSHOW platform. This allows users to track and view details of their earnings in real-time",
        },
        {
          title: "3. Withdrawal Options:",
          answer:
            "DAK allows users to withdraw funds from their earning account, and users can submit withdrawal requests through the DAKSHOW interface. Users can choose suitable payment methods or crypto wallets to receive their earnings",
        },
        {
          title: "4. Reports and Statistics:",
          answer:
            "DAKSHOW provides detailed reports and statistics on users' earning performance, including charts, figures, and analysis to help users better understand their success and progress",
        },
        {
          title: "5. Customer Support:",
          answer:
            "If users have any questions or issues related to earning on DAKSHOW, they can contact DAK's customer support team through the provided support channels, such as email, website, or other communication means",
        },
        {
          title: "6. Connected Crypto Wallet:",
          answer:
            "Users use their personal crypto wallet to receive tokens and manage their NFT fractions.",
        },
        {
          title: "7. On-Chain Data:",
          answer:
            "All data regarding transactions generated within the DAK ecosystem is updated on the Blockchain through Chainlinks. This data can be viewed, analyzed, and downloaded through Dak Explorer. Therefore, users can actively, independently, and transparently access and audit the entire system",
        },
      ],
    },
  ];
  const detailAnswerKOL = [
    {
      content_type: 1,
      type: 1,
      header:
        "As an influencer and content creator, you can consider the following money-making activities:",
      answers: [
        {
          title: "1. Sharing content on DAKSHOW:",
          answer:
            "You can leverage the DAKSHOW platform to share compelling content from popular platforms and earn income based on views and Votes according to community guidelines.",
        },
        {
          title: "2. Creating content on DAKSHOW:",
          answer:
            "Your high-quality and unique content can generate income based on views and Votes from the community through Ownership Verification and Top Posts mechanisms.",
        },
        {
          title: "3. Activating profit-sharing from advertising on DAKSHOW:",
          answer:
            "DAKSHOW will share advertising revenue earned from your content when you meet the specified community conditions.",
        },
        {
          title: "4. Selling/renting NFT fractions of your content:",
          answer:
            "Your created content can now be minted into NFT fractions in any desired quantity. These fractions can be sold or rented, generating income for you. The co-owners of these fractions also share in the profits from your created content.",
        },
        {
          title: "5. Creating content for advertisers:",
          answer:
            "With your fame and influence, you can collaborate with advertisers to create unique advertising content and earn income from this activity. The pricing for this content creation will be automatically agreed upon between you and the advertiser through DIMA.",
        },
        {
          title: "6. Distributing content for advertisers:",
          answer:
            "You can utilize your talent and influence to distribute content for advertisers and earn income from this activity. The pricing for this content creation will be automatically agreed upon between you and the advertiser through DIMA.",
        },
      ],
    },
    {
      content_type: 2,
      type: 1,
      header:
        "DAK provides financial support and investment opportunities for influencers and content creators through the following means:",
      answers: [
        {
          title: "1. Financial support for content development:",
          answer:
            "DAK offers financial assistance to influencers and content creators to invest in the development of high-quality, unique, and engaging content. This is accomplished through community fund mechanisms for content production, content proposals, support for the creative environment, and connecting resources to serve the creative process.",
        },
        {
          title: "2. Investment opportunities in the DAK ecosystem:",
          answer:
            "Influencers and content creators have the opportunity to invest in the DAK ecosystem by purchasing DAK Tokens, contributing equipment, participating in Staking, or providing sponsorship for DAK's development projects. This helps increase financial growth opportunities for participants while strengthening the sustainable development of the ecosystem.",
        },
        {
          title: "3. Facilitating collaboration with partners:",
          answer:
            "DAK facilitates the connection between collaborators and partners within the ecosystem, including advertisers, producers, commercial partners, community developers, and more. This helps attract strong partnerships and brings about growth benefits for both the ecosystem and its members.",
        },
      ],
    },
    {
      content_type: 1,
      type: 2,
      header:
        "To participate in the DAK ecosystem's monetization program, influencers and content creators need to comply with the following policies and regulations:",
      answers: [
        {
          title: "1. Account registration and verification:",
          answer:
            "To participate in the earning and investment program, influencers and content creators need to register and verify their accounts on the DAKSHOW platform. This ensures the accuracy and reliability of personal information and accounts during the earning and transaction process.",
        },
        {
          title: "2. Content quality guidelines:",
          answer:
            "DAK has standards and regulations regarding content quality to ensure that influencers and content creators provide high-quality, engaging, and relevant content aligned with the ecosystem's objectives. This helps maintain the platform's quality and credibility.",
        },
        {
          title:
            "3. Requirements for participating in the profit-sharing advertising program:",
          answer:
            "To activate this feature, you need to meet two conditions:\n" +
            "\t" +
            "+  Account verification.\n" +
            "\t" +
            "+ Being an Active Member of the platform. An Active Member is someone actively staking with the community, and once the staking period ends, they will no longer be considered an Active Member. This ensures transparency and fairness in profit sharing.",
        },
        {
          title: "4. Requirements for financial support:",
          answer:
            "To be eligible for community funding, you need to meet the following conditions:\n" +
            "\t" +
            "+ Account verification.\n" +
            "\t" +
            "+ Being an Active Member of the platform.\n" +
            "\t" +
            "+ Meeting the Interactive Sentiment Index criteria based on AMR analysis.\n",
        },
        {
          title:
            "5. Requirements for participating in content production/distribution programs for advertisers:",
          answer:
            "To be proposed to advertisers, you need to meet the following conditions:\n" +
            "\t" +
            "+ Account verification.\n" +
            "\t" +
            "+ Being an Active Member of the platform.\n" +
            "\t" +
            "+ Meeting the advertiser's requirements based on AMR analysis.\n",
        },
      ],
    },
    {
      content_type: 1,
      type: 3,
      header:
        "Below are the policies and regulations related to withdrawing funds and using earned profits within the DAK ecosystem:",
      answers: [
        {
          title: "1. Withdrawal from the DAK account:" + "\n",
          answer:
            "\t" +
            "+ To withdraw funds from the DAK account, users need to undergo account verification on the DAKSHOW platform to ensure security and reliability." +
            "\n" +
            "\t" +
            "+ Once the account is verified, users can request withdrawals from their account using supported payment methods." +
            "\n",
        },
        {
          title: "2. Supported payment methods:" + "\n",
          answer:
            "\t" +
            "+ DAK provides multiple payment methods for users to withdraw funds and use their earned profits, including bank transfers, e-wallets, cryptocurrencies, and other online payment methods." +
            "\n" +
            "\t" +
            "+ Payment methods must comply with relevant regulations and financial transaction laws." +
            "\n",
        },
        {
          title: "3. Withdrawal processing time:" + "\n",
          answer:
            "\t" +
            "+ The processing time for withdrawals from the DAK account may vary depending on the chosen payment method and the platform's security verification process." +
            "\n" +
            "\t" +
            "+ DAK will strive to minimize the processing time to ensure users receive their funds conveniently and promptly." +
            "\n",
        },
        {
          title: "4. Withdrawal limits and conditions:" + "\n",
          answer:
            "\t" +
            "+ DAK may impose certain limits and conditions on withdrawals, including minimum and maximum withdrawal amounts per transaction." +
            "\n" +
            "\t" +
            "+ These conditions may vary depending on the financial situation and community policies of DAK." +
            "\n",
        },
        {
          title: "5. Use of earned profits:" + "\n",
          answer:
            "\t" +
            "+ Users can utilize their earned profits from activities on DAK to purchase products and services within the platform or withdraw them to supported payment methods." +
            "\n" +
            "\t" +
            "+ Earned profits can also be used to invest in other activities within the DAK ecosystem or enhance financial development opportunities for users." +
            "\n",
        },
        {
          title: "6. Transaction security and user information:" + "\n",
          answer:
            "\t" +
            "+ DAK is committed to ensuring the security and safety of financial transactions and user information." +
            "\n" +
            "\t" +
            "+ User transactions and personal information will be encrypted and reliably protected to prevent any leakage or misuse of personal and financial information." +
            "\n",
        },
      ],
    },
    {
      content_type: 2,
      type: 3,
      header:
        "Users can track their earnings and manage their earning accounts as follows:",
      answers: [
        {
          title: "1. Personal wallet:",
          answer:
            "DAKSHOW provides users with a personal wallet where they can view information about their account, including their earnings and account balance.",
        },
        {
          title: "2. Transaction history:",
          answer:
            "Users can access their transaction history, which displays transactions related to earning money on the DAKSHOW platform. This allows users to track and view details of their completed transactions and real-time earnings.",
        },
        {
          title: "3. Withdrawal options:",
          answer:
            "DAK allows users to withdraw funds from their earning accounts. Users can submit withdrawal requests through the DAKSHOW interface. They can choose suitable payment methods or e-wallets to receive their earned money.",
        },
        {
          title: "4. Reports and statistics:",
          answer:
            "DAKSHOW provides detailed reports and statistics on users' earning performance, including charts, figures, and analysis to help users understand their level of success and progress.",
        },
        {
          title: "5. Customer support:",
          answer:
            "If users have any inquiries or issues related to earning money on DAKSHOW, they can contact DAK's customer support team through provided support channels such as email, website, or other communication means.",
        },
        {
          title: "6. Connected crypto wallet:",
          answer:
            "Users use their personal crypto wallets to receive tokens and manage their NFT fractions.",
        },
        {
          title: "7. On-chain data:",
          answer:
            "All transaction data generated within the DAK ecosystem is updated on the Blockchain through Chainlinks. This data can be viewed, analyzed, and downloaded through Dak Explorer. As a result, users can actively, independently, and transparently access and audit the entire system.",
        },
      ],
    },
    {
      content_type: 1,
      type: 4,
      header:
        "When joining the DAK community as an influencer and content creator, you can receive the following significant benefits and value:",
      answers: [
        {
          title: "1. Earning potential:",
          answer:
            "DAK provides you with multiple opportunities to earn money by sharing content on the DAKSHOW platform, creating content, and receiving profit shares from advertising, selling/renting NFT fractions, and other activities. You can increase your income and generate passive income from your content.",
        },
        {
          title: "2. Increased influence:",
          answer:
            "Participating in the DAK community helps you expand your influence and achieve fame. When you have high-quality and engaging content that attracts a large audience, you can become a prominent influencer in your field.",
        },
        {
          title: "3. Content ownership management:",
          answer:
            "DAK uses Ownership Verification mechanisms to ensure that you have full ownership and control over the content you create. This helps protect creativity and ensures that you receive deserving value for your efforts.",
        },
        {
          title: "4. Financial support and investment:",
          answer:
            "DAK provides financial support and investment opportunities for community members. You can receive financial support to develop high-quality content, invest in the DAK ecosystem, and increase financial growth opportunities.",
        },
        {
          title: "5. Networking and collaboration:",
          answer:
            "Joining the DAK community helps you connect and collaborate with collaborators and partners within the ecosystem. You can leverage collaboration opportunities with advertisers, producers, community developers, and many other partners to enhance development and create exciting projects.",
        },
        {
          title: "6. Support and guidance:",
          answer:
            "DAK offers support and guidance on platform usage, regulations, and community opportunities. You can receive guidance and support from the development and management team of DAK to optimize your experience.",
        },
        {
          title: "7. Interaction and excitement:",
          answer:
            "Engaging in the DAK community allows you to interact with other members and participate in exciting activities such as discussions, voting, content following, and engagement in award programs and events.",
        },
      ],
    },
  ];
  return (
    <div>
      {location.pathname === "/Q&A/User's-Q&A" ? (
        <>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "32px",
              marginTop: "32px",
            }}
          >
            User's Q&A
          </h1>
          <UserQA
            userQuestions={userQuestions}
            questionAndanswer={questionAndanswer}
            detailAnswer={detailAnswer}
          />
        </>
      ) : (
        <>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "32px",
              marginTop: "32px",
            }}
          >
            KOL's Q&A
          </h1>
          <UserQA
            userQuestions={userQuestionsKOL}
            questionAndanswer={questionAndanswerKOL}
            detailAnswer={detailAnswerKOL}
          />
        </>
      )}
    </div>
  );
};
export default QA;
