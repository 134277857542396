import "./style.scss";

const Ecosystem_AI = () => {
  return (
    <>
      <div className="wrap_ai">
        <div className="ai_up">
          <div className="ai_up_left">
            <div className="line_1"></div>
            <div className="line_2"></div>
            <div className="line_3"></div>
            <div className="hr-top"></div>
            <div className="ai_up_left_content">
              <h3>AI</h3>
              <p>
                DAK unleashes the potential of artificial intelligence (AI)
                across its ecosystem, empowering the DAO, AMR, and DIMA. With
                state-of-the-art machine learning and deep learning models, DAK
                AI revolutionizes community management, market research, and
                influencer marketing. Explore a world of possibilities with
                DAK's AI-driven solutions.
              </p>
            </div>
            <div className="hr-bottom"></div>
          </div>
          <div className="ai_up_right">
            <div className="hr-top"></div>
            <div className="ai_up_right_content">
              <p>
                <strong>
                  <b>DIMA</b>
                </strong>{" "}
                - Decentralized Influencer Marketing utilizes AI to create a
                sustainable advertising model for influencers and content
                creators. Through DAKSHOW platforms, it enables direct
                interaction between advertisers and influencers, ensuring
                transparency, security, cost optimization, collaboration, and
                scalability based on individual needs
              </p>
            </div>
            <div className="hr-bottom"></div>
          </div>
        </div>
        <div className="ai_down">
          <div className="ai_down_left">
            <div className="hr-top"></div>
            <div className="ai_down_left_content">
              <p>
                <strong>
                  <b>DAO</b>
                </strong>{" "}
                - Decentralized Autonomous Organization. AI boosts DAO
                operations through efficient decision-making and enhanced user
                engagement. By analyzing data and leveraging advanced
                algorithms, it enables informed decision-making, process
                automation, and dynamic community governance. With AI, the DAO
                becomes a hub of collaboration and innovation.
              </p>
            </div>
            <div className="hr-bottom"></div>
          </div>

          <div className="ai_down_right">
            <div className="hr-top"></div>
            <div className="ai_down_right_content">
              <p>
                <strong>
                  <b>AMR</b>
                </strong>{" "}
                - Autonomous Market Research is an AI-powered method that
                automates data collection, processing, and analysis of content
                and influencer impact. By utilizing the Interactive Sentiment
                Index, AMR provides precise and reliable results,
                revolutionizing market research practices and empowering DIMA.
              </p>
            </div>
            <div className="hr-bottom"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ecosystem_AI;
