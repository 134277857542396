import React, { useState, useEffect } from "react";

const UserQA = ({ userQuestions, questionAndanswer, detailAnswer }) => {
  const [type, setType] = useState(1);
  const [contentType, setContenttype] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setType((prevA) => (prevA % 4) + 1);
      setContenttype(1);
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setContenttype(
        (prevA) =>
          (prevA %
            questionAndanswer.find((item) => item.type === type).contents
              .length) +
          1
      );
    }, 10000);
    return () => clearInterval(interval);
  }, [type]);
  return (
    <div className="container">
      <section className="section_1">
        <h2>Discussion topic</h2>
        <div className="quest_title">
          {userQuestions.map((question) => (
            <p
              key={question.type}
              className={`${type === question.type && "lighten"}`}
              onClick={() => {
                setType(question.type);
                setContenttype(1);
              }}
            >
              {question.title}
            </p>
          ))}
        </div>
      </section>
      <section className="section_2">
        <article className="question_area">
          {questionAndanswer.map((ques) => {
            if (ques.type === type) {
              return ques.contents.map((content) => (
                <div
                  key={content.content_type}
                  className={`question_answer quest_${content.content_type} ${
                    content.content_type === contentType
                      ? "question_answer_active"
                      : ""
                  }`}
                  onClick={() => setContenttype(content.content_type)}
                >
                  <p className="question">{content.question}</p>
                  <span className="mini_answer">{content.answer}</span>
                </div>
              ));
            }
          })}
        </article>
      </section>
      <section className="section_3">
        <p className="answer_header">
          {
            detailAnswer.find(
              (item) => item.type === type && item.content_type === contentType
            )?.header
          }
        </p>
        <div>
          {detailAnswer
            .find(
              (item) => item.type === type && item.content_type === contentType
            )
            ?.answers.map((answer, index) => (
              <div key={index} className="answer">
                <p className="content">
                  {" "}
                  <span className="title">{answer.title}</span> {answer.answer}
                </p>
              </div>
            ))}
        </div>
      </section>
    </div>
  );
};

export default UserQA;
