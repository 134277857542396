import './Backer.scss'
import Footer from '../../../Footer/Footer'

const Backer = () => {
    return (
        <>
        <Footer/>
        </>
    )
}

export default Backer