import React from "react";
import "./Revenue.scss";
import RM from "../../../../../assets/images/rm.png";
import RM2 from "../../../../../assets/images/rm2.png";

const Revenue = () => {
  return (
    <div className="revenue">
      <div
        data-aos="fade-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1500"
        className="revenue_wrap"
      >
        <div className="revenue_wrap_left">
          <div className="img">
            <img src={RM} alt="Revenue release" />
          </div>
        </div>
        <div className="revenue_wrap_right">
          <div className="img">
            <img src={RM2} alt="Revenue release" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
