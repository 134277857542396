import "./style.scss";
import overview from "../../../../../assets/images/overview.png";

const Ecosystem_Overview = () => {
  return (
    <>
      <div className="wrap_overview">
        <div className="overview_left">
          {/* <div className="circle_1">
            <div className="ball circle_1_1">
              <span>DAK SHOW</span>
            </div>
            <div className="ball circle_1_2">
              <span>GAME FI</span>
            </div>
            <div className="ball circle_1_3"></div>
            <div className="ball circle_1_4">
              <span>DAK BLOCK</span>
            </div>
            <div className="ball circle_1_5"></div>

            <div className="circle_2">
              <div className="ball circle_2_1"></div>
              <div className="ball circle_2_2">
              <span>DAK TOKEN</span>
              </div>
              <div className="ball circle_2_3">
                <span>DAK POINT</span>
              </div>

              <div className="circle_3">
                <div className="ball circle_3_1"></div>
                <div className="ball circle_3_2">
                <span>AI</span>
                </div>
                <div className="ball circle_3_3"></div>
                <div className="ball circle_3_4">
                  <span>DAK CLOUD COMPUTING -DCC</span>
                </div>
              </div>
            </div>
          </div> */}
          <img src={overview} alt="overview" />
        </div>
        <div className="overview_right">
          <div>
            <div className="hr-top"></div>
            <span>
              The DAK ecosystem, powered by DAK Token & DAK Point, seamlessly
              connects its components. Users can contribute and connect within
              the vibrant DAK community, earning rewards for everyday activities
              like gaming, social networking, and entertainment. Active
              participation is encouraged, unlocking the benefits of
              enthusiastic engagement.
            </span>
            <div className="hr-bottom"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ecosystem_Overview;
