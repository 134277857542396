import './index.scss';
import Dashboard from './components/Dashboard/Dashboard';

function App() {
  return (
    <>
      <div>
        <Dashboard/>
      </div>
    </>
    );
}

export default App;
