import "./styles.scss";

const Ecosystem_DB_DC = () => {
  return (
    <>
      <div className="wrap_dakblock">
        <div className="dakblock_left">
          <p>Flexible ledger system</p>
          <div className="hr-top"></div>
          <div className="dakblock_left_content">
            <h3>DAKBLOCK</h3>
            <p>
              DAK Block is a powerful hybrid chain that combines blockchain and
              block lattice technologies, offering secure and transparent
              transactions, high scalability, fast processing speeds, low fees,
              and seamless cross-chain interoperability. Its innovative approach
              unlocks transformative applications across various industries
            </p>
          </div>
          <div className="hr-bottom"></div>
        </div>
        <div className="dakblock_right">
          <p>Decentralized cloud computing system</p>
          <div className="hr-top"></div>
          <div className="dakblock_right_content">
            <h3>DAK CLOUD COMPUTING – DCC</h3>
            <p>
              A Decentralized Cloud offers increased system availability,
              reliability, and scalability, allowing applications to run
              simultaneously on multiple nodes for improved flexibility and
              performance. Furthermore, it reduces costs and enhances security
              by eliminating a single point of control. Embracing DCC promises a
              bright and promising future for businesses and organizations.
            </p>
          </div>
          <div className="hr-bottom"></div>
        </div>
      </div>
    </>
  );
};

export default Ecosystem_DB_DC;
