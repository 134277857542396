import React, { useEffect, useState } from "react";
import { Button, Modal as ModalComingSoon } from "antd";
import "./Home.scss";
import logoStech from "../../../../assets/images/newimg.png";
import Banner from "../../../../assets/images/LogoDakShow.png";
import Token from "../../../../assets/images/DAK2.png";
import file from "../../../../assets/file/WHITEPAPER .pdf";
import { useViewport } from "../../../../utils/ViewPortHook";
import logoDak from "../../../../assets/images/DAK2.png";
//react-pdf
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Modal from "react-modal";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  const language = localStorage.getItem("language") || "en";
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLayout, setModalLayout] = useState();
  const [ModelWidth, setModelWidth] = useState(500);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal =(num)=>{
      setIsModalOpen(true);
      switch (num) {
        case 1:
          setModelWidth(500)
            setModalLayout( <h1 >Coming Soon</h1>);
          break;
        case 2:
          setModelWidth(700)
          setModalLayout(<iframe src="https://drive.google.com/file/d/1DFXL6FiabpeKEJ7sFS02-6Z6WP2Vwr-m/preview" width={ModelWidth+150} height={480}></iframe>);
          break;
        default:
          break;
      }
  }
  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);
  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log(`Loaded ${numPages} pages`);
    setNumPages(numPages);
  };

  const viewPort = useViewport();
  console.log(file);
  useEffect(() => {
    if (viewPort.width < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [viewPort.width]);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  return (
    <>
      <div>
        <div className="Logo-Dak">
          <img src={logoStech} alt="Logo Stech VN" />
        </div>
        <div className="Content-Dak">
          <p>EMPOWERING CREATORS WITH DECENTRALIZED CLOUD COMPUTING</p>
        </div>
        <ModalComingSoon
        bodyStyle={{
          backgroundColor:"#ecd773",
          margin: "-24px",
          padding: "24px",
          borderRadius: "10px",
          color: "black",
          textAlign:"center"
        }}
        centered
        width={ModelWidth}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
       {modalLayout}
      </ModalComingSoon>
        <div className="Buy-Token">
          <a onClick={() => showModal(1)}>BUY TOKEN NOW</a>
        </div>
        
        <div className="Middle-Content-Dak">
          <a onClick={() => showModal(2)}>Watch About Us</a>
          <img src={Banner} alt="Testing" width={150} height={150} />
          <a target={"_blank"} rel="noreferrer" onClick={openModal}>
            Read Whitepaper
          </a>
          <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </Modal>
        </div>
        <div className="bg-img"></div>
      </div>

      <div className="wrap">
        <div className="stars">
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
          <div className="star" />
        </div>
      </div>
    </>
  );
};

export default Home;
