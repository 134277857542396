import "./style.scss";
import Metaverse from "../../../../../assets/images/metaverse.png";

const AboutDak = () => {
  return (
    <div className="about_main">
      <div className="about_wrap">
        <div className="about_wrap_left">
          <div className="hr-top"></div>
          <h3>About Dak</h3>
          <p>
            DAK provides a technological solution to take advantage of the
            community's available computer and device resources to create a
            Decentralized Cloud Computing network, which brings economic
            benefits to the community through their devices, operations and
            interactions.
          </p>
        </div>
        <div className="about_wrap_right">
          <img src={Metaverse} alt="Metaverse" />
        </div>
      </div>
    </div>
  );
};

export default AboutDak;
