import "./Register.scss";

const Register = () => {
  return (
    <div className="Register">
      <a href="https://beta.dakshow.com" target="_blank">
        Try Beta Version
      </a>
    </div>
  );
};

export default Register;
