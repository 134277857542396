import "./style.scss";

const Ecosystem_GameFi = () => {
  return (
    <>
      <div className="wrap_game">
        <div className="game_up">
          <div className="game_up_left">
            <div className="line_1"></div>
            <div className="line_2"></div>
            <div className="game_up_left_button">
              <strong>GAME FI</strong>
              <div id="container-stars">
                <div id="stars" />
              </div>
              <div id="glow">
                <div className="circle" />
                <div className="circle" />
              </div>
            </div>
            <p className="game_center">
              DAK introduces two protocols that facilitate connections between
              game developers and the DAK community:
            </p>
          </div>
          <div className="game_up_right">
            <div className="hr-top"></div>
            <div className="game_up_right_content">
              <h3>CROSS - UNIVERSE CHARACTER</h3>
              <p>
                Cross-Universe Character: Leveraging NFT technology, players can
                utilize their unique characters across multiple games, enhancing
                their gaming experience and ownership.
              </p>
            </div>
            <div className="hr-bottom"></div>
          </div>
        </div>

        <div className="game_down">
          <div className="hr-top"></div>
          <div className="game_down_content">
            <h3>SWAPPING DAK POINT</h3>
            <p>
              DAK POINT Swapping: Players have the ability to convert reward
              points or cryptocurrencies from different games into DAK POINT,
              with the DAO determining the conversion rate. This enables
              seamless value exchange within the DAK ecosystem.
            </p>
          </div>
          <div className="hr-bottom"></div>
        </div>
      </div>
    </>
  );
};

export default Ecosystem_GameFi;
