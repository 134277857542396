import React, { useRef, useState } from "react";
import { Pie } from "@ant-design/plots";
import Logo from "../../../../../assets/images/LogoDakShow.png";
import "./Allocation.scss";

const Allocation = () => {
  const data = [
    {
      type: "Community",
      value: 35
    },
    {
      type: "Contributors",
      value: 19.25
    },
    {
      type: "ICO",
      value: 15.75
    },
    {
      type: "Founding Team",
      value: 10
    },
    {
      type: "Stakers",
      value: 5
    },
    {
      type: "Maketers",
      value: 5
    },
    {
      type: "Development",
      value: 5
    },
    {
      type: "Foundation Reserve",
      value: 5
    },
    
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    color: "transparent",
    pieStyle: {
      stroke: "l(0) 0.1:#ecd773 0.4:#fdfcc9 0.7:#fffedd 1:#c8992f",
      lineWidth: 2,
      strokeOpacity: 1,
      shadowColor: "#f8d205",
      shadowBlur: 1,
      shadowOffsetX: 1,
      shadowOffsetY: 1,
      cursor: "pointer"
    },
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 20,
        fill: "yellow"
      }
    },
    interactions: [],
    statistic: {
      title: false,
      content: {
        style: {},
        content: `<div class=wrapLogo><img class=logoPie src=${Logo} alt=Logo /></div>`
      }
    },
    legend: false,
    tooltip: {
      customContent: (type) => {
        return `<div style={padding: "20px 10px"}>${type}</div>`;
      }
    },
    animation: {
      appear: {
        animation: "path-in",
        duration: 2000
      }
    }
  };
  const [getType, setGetType] = useState("");

  return (
    <div className="allocation">
      <h1>TOKEN ALLOCATION</h1>
      <div className="allocation_wrap">
        <div className="allocation_wrap_chart">
          <Pie
            onEvent={(plot) => {
              plot.on("element:click", (...args) => {
                let a = { ...args };
                setGetType(a[0].data.data.type);
              });
            }}
            {...config}
          />
        </div>
        <div className="allocation_wrap_table">
          <table>
            <tbody>
              {data.map((item) => (
                <tr
                  key={item.type}
                  className={getType === item.type && "active"}
                >
                  <td style={{ paddingRight: "6px" }}>{item.type}</td>
                  <td>{item.value}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Allocation;
