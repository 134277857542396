import "./styles.scss";
import CloudImg from "../../../../../assets/images/cloudcomputing.png";

const EcosystemDAKSHOW = () => {
  return (
    <>
      <div className="wrap_dakshow">
        <div className="dakshow_left">
          <img src={CloudImg} alt="cloud computing" />
        </div>
        <div className="dakshow_right">
          <div>
            <div className="hr-top"></div>
            <span>
              DAKSHOW is the perfect fusion of Social Media Content and Economy.
              It rewards users for their influence and engagement on social
              media platforms. This unique platform empowers users through
              active participation and voting, promoting information
              verification and creating a vibrant community of engagement and
              financial opportunities.
            </span>
            <span className="hr-bottom"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EcosystemDAKSHOW;
