import React from "react";
import DT from "../../../../../assets/images/dt.png";
import "./Token.scss";
const Token = () => {
  return (
    <div className="daktoken">
      <div className="daktoken_wrap">
        <div className="daktoken_wrap_left">
          <div
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            className="img"
          >
            <img src={DT} alt="Dak Token" />
          </div>
        </div>
        <div
          data-aos="fade-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          className="daktoken_wrap_right"
        >
          <h3>
            DAK Token (DAK): The DAK ecosystem utilizes a cryptocurrency and
            token-based DAO
          </h3>
          <ul>
            <li>
              - <span>Token name:</span> DAK Token
            </li>
            <li>
              - <span>Ticker:</span> DAK
            </li>
            <li>
              - <span>Blockchain:</span> BSC
            </li>
            <li>
              - <span>Function:</span> CryptoCurrency, DAO Vote
            </li>
            <li>
              - <span>Total Supply:</span> 1.000.000.000 DAK
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Token;
