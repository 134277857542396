import React from "react";
import Logo from "../../../../../assets/images/LogoDakShow.png";
import "./Release.scss";
import Revenue from "../Tokenomic-Revenue/Revenue";
const Release = () => {
  return (
    <div className="release" style={{ paddingBottom: "56px" }}>
      <h1>TOKEN RELEASE SCHEDULE</h1>
      <div
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1500"
        className="release_wrap"
      >
        <table>
          <tbody>
            <tr>
              <td>
                <span>36 Months</span>
              </td>
              <td>
                <span>
                  <i>Contributors</i>
                </span>
              </td>
              <td>
                <span>
                  Unlock 8.3 %/quarter starting from completing the transaction
                </span>
              </td>
            </tr>

            <tr>
              <td>
                <span>36 Months</span>
              </td>
              <td>
                <span>
                  <i>Founding Team</i>
                </span>
              </td>
              <td>
                <span>Unlock 12.5 %/quarter from 13th month</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>36 Months</span>
              </td>
              <td>
                <span>
                  <i>Developers</i>
                </span>
              </td>
              <td>
                <span>Unlock 8.3 %/quarter from 1st month</span>
              </td>
            </tr>

            <tr>
              <td>
                <span>36 Months</span>
              </td>
              <td>
                <span>
                  <i>Foundation Reserve</i>
                </span>
              </td>
              <td>
                <span>Unlock 12.5 %/quarter from 13th month</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h1>TOKEN REVENUE MODEL</h1>
      <Revenue />
    </div>
  );
};

export default Release;
