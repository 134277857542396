import React, { useEffect, useState } from "react";
import "./Footer.scss";
import file from "../../assets/file/WHITEPAPER .pdf";
//react-pdf
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import Modal from "react-modal";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);
  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log(`Loaded ${numPages} pages`);
    setNumPages(numPages);
  };
  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </Modal>
      <div className="Footer">
        <div className="contentList">
          <ul>
            <li style={{ color: '#ecd773' }}>info@dakshow.com</li>
            <li>
              © 2024 Hooked All Rights Reserved. Powered by <a href="https://stech.international" target="_blank" style={{ color: '#ecd773' }}
>
  STECH INTERNATIONAL
</a>
            </li>
          </ul>
        </div>

        <div className="switchPage">
          <ul>
            <li>
              <a onClick={openModal}>Whitepaper</a>
            </li>
            <li>
              <a href="/about-us/about-dak">About_Us</a>
            </li>
            {/* <li>
              <a href="#">Term_of_Service</a>
            </li>
            <li>
              <a href="#">Privacy_Policy</a>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
