import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Login from "../Dashboard/Routed/Login/Login";
import Register from "../Dashboard/Routed/Register/Register";
import { en, vn } from "./language";

import "./MenuItem.scss";

const MenuItem = ({ isMobile, setToggleDropdownNavBarMenu }) => {
  const language = localStorage.getItem("language") || "en";
  const navigate = useNavigate();
  const location = useLocation();

  const navigateItems = [
    { key: "/", label: language === "vn" ? vn.HOME : en.HOME },
    {
      key: "/about-us",
      label: language === "vn" ? vn.ABOUT_US : en.ABOUT_US,
      children: [
        {
          key: "/about-us/about-dak",
          label: language === "vn" ? vn.ABOUT_DAK : en.ABOUT_DAK,
        },
        {
          key: "/about-us/vision",
          label: language === "vn" ? vn.VIMIVAL : en.VIMIVAL,
        },
      ],
    },
    {
      key: "/ecosystem",
      label: language === "vn" ? vn.ECOSYSTEM : en.ECOSYSTEM,
      children: [
        {
          key: "/ecosystem/overview",
          label:
            language === "vn" ? vn.ECOSYSTEM_OVERVIEW : en.ECOSYSTEM_OVERVIEW,
        },
        {
          key: "/ecosystem/dakshow",
          label:
            language === "vn" ? vn.ECOSYSTEM_DAKSHOW : en.ECOSYSTEM_DAKSHOW,
        },
        {
          key: "/ecosystem/dakblock-dakcloud",
          label:
            language === "vn"
              ? vn.ECOSYSTEM_DAKBLOCK_DAKCLOUD
              : en.ECOSYSTEM_DAKBLOCK_DAKCLOUD,
        },
        {
          key: "/ecosystem/ai",
          label: language === "vn" ? vn.ECOSYSTEM_AI : en.ECOSYSTEM_AI,
        },
        {
          key: "/ecosystem/game-fi",
          label:
            language === "vn" ? vn.ECOSYSTEM_GAME_FI : en.ECOSYSTEM_GAME_FI,
        },
      ],
    },
    {
      key: "/tokenomic",
      label: language === "vn" ? vn.TOKENOMIC : en.TOKENOMIC,
      children: [
        { key: "/tokenomic/dak-point", label: "Dak Point" },
        { key: "/tokenomic/dak-token", label: "Dak Token" },
        { key: "/tokenomic/token-allocation", label: "Token Allocation" },
        { key: "/tokenomic/token-release", label: "Token Release Schedule" },
        // { key: "/tokenomic/token-revenue", label: "Token Revenue Model" },
      ],
    },
    { key: "/team", label: language === "vn" ? vn.TEAM : en.TEAM },
    // { key: "/backer", label: language === "vn" ? vn.BACKER : en.BACKER },
    { key: "/roadmap", label: language === "vn" ? vn.ROADMAP : en.ROADMAP },
    {
      key: "/Q&A",
      label: "Q&A",
      children: [
        { key: "/Q&A/User's-Q&A", label: "User" },
        { key: "/Q&A/KOL's-Q&A", label: "KOL" },
      ],
    },
  ];

  return (
    <>
      <Menu
        className="MenuItem"
        mode={`${isMobile ? "vertical" : "horizontal"}`}
        items={navigateItems}
        defaultSelectedKeys={[window.location.pathname]}
        selectedKeys={[`/${location.pathname.split("/")[1]}`]}
        onClick={({ key }) => {
          navigate(key);
          setToggleDropdownNavBarMenu(false);
        }}
      />
      <div className="Menu_btn">
        <Register />
      </div>
    </>
  );
};

export default MenuItem;
