import './Tokenomic.scss'


const Tokenomic = () => {
    return (
        <>
        Test1
        </>
    )
}

export default Tokenomic