import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router";
import AboutUs from "../MenuBar/ItemBar/About-Us/AboutUs";
import Home from "../MenuBar/ItemBar/Home/Home";
import Ecosystem from "../MenuBar/ItemBar/Ecosystem/Ecosystem";
import Tokenomic from "../MenuBar/ItemBar/Tokenomic/Tokenomic";
import Team from "../MenuBar/ItemBar/Team/Team";
import Backer from "../MenuBar/ItemBar/Backer/Backer";
import Roadmap from "../MenuBar/ItemBar/RoadMap/Roadmap";
import AboutDak from "../MenuBar/ItemBar/About-Us/AboutDak/AboutDak";
import ViMiVal from "../MenuBar/ItemBar/About-Us/Vi-Mi-Val/ViMiVal";
import Ecosystem_Overview from "../MenuBar/ItemBar/Ecosystem/Ecosystem-Overview/Ecosystem-Overview";
import EcosystemDAKSHOW from "../MenuBar/ItemBar/Ecosystem/EcosystemDakShow/EcosystemDAKSHOW";
import Ecosystem_DB_DC from "../MenuBar/ItemBar/Ecosystem/Ecosystem-DakBlock-DakCloud/Ecosystem-DB-DC";
import Ecosystem_AI from "../MenuBar/ItemBar/Ecosystem/Ecosystem-AI/Ecosystem-AI";
import Ecosystem_GameFi from "../MenuBar/ItemBar/Ecosystem/Ecosystem-GameFi/Ecosystem-GameFi";
import DakPoint from "../MenuBar/ItemBar/Tokenomic/Tokenomic-DakPoint/DakPoint";
import Allocation from "../MenuBar/ItemBar/Tokenomic/Tokenomic-Allocation/Allocation";
import Release from "../MenuBar/ItemBar/Tokenomic/Tokenomic-Release/Release";
import Revenue from "../MenuBar/ItemBar/Tokenomic/Tokenomic-Revenue/Revenue";
import Token from "../MenuBar/ItemBar/Tokenomic/Tokenomic-Token/Token";
import QA from "../MenuBar/ItemBar/QA/QA";

const RouterItem = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);
  const activeTitle = [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "/about-us",
      title: "About",
    },
    {
      path: "/about-us/vision",
      title: "Vision Mission Value",
    },
    {
      path: "/ecosystem/overview",
      title: "Overview",
    },
    {
      path: "/ecosystem/dakshow",
      title: "DAKSHOW",
    },
    {
      path: "/ecosystem/dakblock-dakcloud",
      title: "DAKBLOCK DAKCLOUD",
    },
    {
      path: "/ecosystem/ai",
      title: "AI",
    },
    {
      path: "/ecosystem/game-fi",
      title: "GAME FI",
    },
    {
      path: "/tokenomic",
      title: "Tokenomic",
    },
    {
      path: "/tokenomic/dak-point",
      title: "Tokenomic Dak Point",
    },
    {
      path: "/tokenomic/dak-token",
      title: "Tokenomic Dak Token",
    },
    {
      path: "/tokenomic/token-allocation",
      title: "Tokenomic Allocation",
    },
    {
      path: "/tokenomic/release",
      title: "Tokenomic Release",
    },
    {
      path: "/tokenomic/revenue",
      title: "Tokenomic Revenue Model",
    },
    {
      path: "/team",
      title: "Team",
    },
    {
      path: "/backer",
      title: "Backers",
    },
    {
      path: "/roadmap",
      title: "RoadMap",
    },
    {
      path: "Q&A/User's-Q&A",
      title: "User's Q&A",
    },
    {
      path: "/Q&A/KOL's-Q&A",
      title: "KOL's Q&A",
    },
  ];
  useEffect(() => {
    activeTitle.map((item) => (
      <>
        {item.path === location.pathname ? (document.title = item.title) : ""}
      </>
    ));
  }, [location.pathname]);
  return (
    <>
      <Routes>
        <Route path="/*" element={<Home />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/about-us/about-dak" element={<AboutDak />} />
        <Route path="/about-us/vision" element={<ViMiVal />} />
        <Route path="/ecosystem" element={<Ecosystem />}></Route>
        <Route path="/ecosystem/overview" element={<Ecosystem_Overview />} />
        <Route path="/ecosystem/dakshow" element={<EcosystemDAKSHOW />} />
        <Route
          path="/ecosystem/dakblock-dakcloud"
          element={<Ecosystem_DB_DC />}
        />
        <Route path="/ecosystem/ai" element={<Ecosystem_AI />} />
        <Route path="/ecosystem/game-fi" element={<Ecosystem_GameFi />} />
        <Route path="/tokenomic" element={<Tokenomic />}></Route>
        <Route path="/tokenomic/dak-point" element={<DakPoint />} />
        <Route path="/tokenomic/dak-token" element={<Token />} />

        <Route path="/tokenomic/token-allocation" element={<Allocation />} />
        <Route path="/tokenomic/token-release" element={<Release />} />
        <Route path="/tokenomic/token-revenue" element={<Revenue />} />
        <Route path="/team" element={<Team />}></Route>
        <Route path="/backer" element={<Backer />}></Route>
        <Route path="/roadmap" element={<Roadmap />}></Route>
        <Route path="/Q&A/User's-Q&A" element={<QA />}></Route>
        <Route path="/Q&A/KOL's-Q&A" element={<QA />}></Route>
      </Routes>
    </>
  );
};

export default RouterItem;
