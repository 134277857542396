export const en = {
    BANNER : "ABOUT US",
    FIRST_CONTENT :{
        title : "STECH GROUP",
        desc1 : "In 2021, STECH Technology & Engineering Joint Stock Company was established with the desire to pioneer the application of new technologies in the 4th industrial revolution - the Digital Revolution in Vietnam.",
        desc2 : "STECH is proud to be a technology startup with a professional team in developing products and solutions for integrating information technology and automation technology. During the operation, STECH always strives with the highest goal of bringing satisfaction to customers through the most optimal services, products and technology solutions. At the same time, we are constantly researching and pioneering new technology trends, contributing to affirming Vietnam's position in the digital transformation.",
        desc3 : "STECH operates in 03 core business areas including: Technology, Fintech and Education. We are constantly expanding our operations in the global market with 3 offices in countries and territories outside of Vietnam."
    },
    SECOND_CONTENT : {
        title1 : "Vision, Mission And Core Values",
        desc1: "STECH sets itself the mission of ' For a better technological future for society', with 3 core pillars: Technology - Data - Education.",
        title2 : "For Customers",
        desc2: "Creating products and services of optimal quality, bringing satisfaction to customers at the highest level.",
    },
    THIRD_CONTENT :{
        title1 : "Meet Our Team",
        title2 : "TECH TEAM"
    } 
}
export const vn = {
    BANNER : "VỀ CHÚNG TÔI",
    FIRST_CONTENT :{
        title : "STECH",
        desc1 : "Năm 2021, Công ty Cổ phần Công nghệ & Kỹ thuật STECH được thành lập với mong muốn tiên phong ứng dụng công nghệ mới trong cuộc cách mạng công nghiệp lần thứ 4 - Cách mạng số tại Việt Nam.",
        desc2 : "STECH tự hào là startup công nghệ có đội ngũ chuyên nghiệp trong việc phát triển các sản phẩm và giải pháp tích hợp công nghệ thông tin và công nghệ tự động hóa. Trong suốt quá trình hoạt động, STECH luôn nỗ lực với mục tiêu cao nhất là mang lại sự hài lòng cho khách hàng thông qua các dịch vụ, sản phẩm và giải pháp công nghệ tối ưu nhất. Đồng thời không ngừng nghiên cứu, đón đầu các xu hướng công nghệ mới, góp phần khẳng định vị thế của Việt Nam trong công cuộc chuyển đổi số.",
        desc3 : "STECH hoạt động trên 03 lĩnh vực kinh doanh cốt lõi bao gồm: Công nghệ, Fintech và Giáo dục. Chúng tôi không ngừng mở rộng hoạt động trên thị trường toàn cầu với 3 văn phòng tại các quốc gia và vùng lãnh thổ ngoài Việt Nam."
    },
    SECOND_CONTENT : {
        title1 : "Tầm Nhìn, Sứ Mệnh Và Giá Trị Cốt Lõi",
        desc1: "STECH đặt cho mình sứ mệnh “Vì một tương lai công nghệ tốt đẹp hơn cho xã hội”, với 3 trụ cột cốt lõi: Công nghệ - Dữ liệu - Giáo dục.",
        title2 : "Dành Cho Khách Hàng",
        desc2: "Tạo ra những sản phẩm, dịch vụ có chất lượng tối ưu, mang lại sự hài lòng ở mức cao nhất cho khách hàng.",
    },
    THIRD_CONTENT :{
        title1 : "THÀNH VIÊN STECH",
        title2 : "ĐỘI NGŨ STECH"
    } 
}