import "./Dashboard.scss";
import MenuItem from "../MenuBar/MenuItem";
import Login from "../Dashboard/Routed/Login/Login";
import Register from "../Dashboard/Routed/Register/Register";
import { Button, Space } from "antd";
import { useViewport } from "../../utils/ViewPortHook";

import { FaBars } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Headers = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  const [toggleDropdownNavBarMenu, setToggleDropdownNavBarMenu] =
    useState(false);
  const viewPort = useViewport();

  const handleDropDownNavMenu = () => {
    setToggleDropdownNavBarMenu((prevState) => !prevState);
  };

  const handleLanguage = (_language) => {
    localStorage.setItem("language", _language);
    setLanguage(_language);
    window.location.reload();
  };

  useEffect(() => {
    if (viewPort.width < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [viewPort.width]);

  return (
    <>
      <header>
        <nav className="navbar-container">
          <div className="nav-logo">
            <Link to={"/"}>
              <img
                loading="lazy"
                src={require("../../assets/images/logo.png")}
                alt="logo"
              />
            </Link>
            {/* <div className="nav-logo-language">
                            <span className={language === 'vn' && 'active'} onClick={() => handleLanguage('vn')}>vn</span>
                            <span className={language === 'en' && 'active'} onClick={() => handleLanguage('en')}>en</span>
                        </div> */}
          </div>

          <div className="nav-items">
            <div className="items">
              <MenuItem />
            </div>
          </div>
          <div className="nav-btns">
            <div className="items">
              <Register />
            </div>
          </div>

          {isMobile ? (
            <>
              <div className="nav-icon-bars">
                <Space wrap>
                  <Button type="primary" onClick={handleDropDownNavMenu}>
                    <FaBars />
                  </Button>
                </Space>
              </div>
              <div
                className={`${
                  toggleDropdownNavBarMenu ? "dropdown-nav-items" : "nav-items"
                }`}
              >
                <Space wrap>
                  <Button type="primary" onClick={handleDropDownNavMenu}>
                    <GrClose />
                  </Button>
                </Space>
                <div className="items">
                  <MenuItem
                    isMobile={isMobile}
                    setToggleDropdownNavBarMenu={setToggleDropdownNavBarMenu}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </nav>
      </header>
    </>
  );
};

export default Headers;
