import "./Roadmap.scss";
import Footer from "../../../Footer/Footer";
import Phase1 from "../../../../assets/images/phase1.png";
import Phase2 from "../../../../assets/images/phase2.png";
import Phase3 from "../../../../assets/images/phase3.png";
import Phase4 from "../../../../assets/images/phase4.png";
import Phase5 from "../../../../assets/images/phase5.png";
import Rocket from "../../../../assets/images/Untitled.png";

import { Collapse, Tooltip } from "antd";

import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation, Pagination } from "swiper";
import { useEffect, useRef, useState } from "react";

const { Panel } = Collapse;
const Roadmap = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleMobile = () => {
      if (window.innerWidth <= 1060) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleMobile();
    window.addEventListener("resize", handleMobile);
    return () => {
      window.removeEventListener("resize", handleMobile);
    };
  }, []);

  const onChange = (key) => {};

  const [height, setHeight] = useState("");
  const [totalHeight, setTotalHeight] = useState("");

  const myElementRef = useRef(null);
  const myElementRef2 = useRef(null);
  const wrapRef = useRef(null);

  const handleOnClick = (phase) => {
    const abc = wrapRef.current;
    const elementHeightsss = abc.offsetHeight;
    setTotalHeight(elementHeightsss);

    console.log(`The height ofsadasdas my-element is ${elementHeightsss}px.`);
    if (phase === 4) {
      const myElement = myElementRef.current;
      console.log({ myElement });
      const elementHeight = myElement.offsetHeight;
      setHeight(elementHeight);
      console.log(`The height of my-element is ${elementHeight}px.`);
    }

    if (phase === 3) {
      const myElement2 = myElementRef2.current;
      const elementHeight2 = myElement2.offsetLeft;
      console.log(
        `The height 222222222222222 of my-element is ${elementHeight2}px.`
      );
    }
  };

  return (
    <>
      <div className="roadmap">
        <h2>Roadmap</h2>
        <Swiper
          // direction={"horizontal"}
          // mousewheel={true}
          //   pagination={{
          //     type: "progressbar"
          //   }}
          //   navigation={{
          //     enabled: true
          //   }}
          //   modules={[Pagination, Navigation, Mousewheel]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="slide slide1">
              {!isMobile ? (
                <div className="wrap_content">
                  <div className="content_up">
                    <div className="content content_up_1">
                      <div className="img">
                        <Tooltip
                          overlayClassName="tooltip"
                          style={{ width: "500px" }}
                          color="#FFDE2E"
                          placement="right"
                          title={
                            <>
                              <h4>
                                <b>
                                  <i>Test out and build the DAK community</i>
                                </b>
                              </h4>
                              <h4>
                                <b>
                                  <i>Technology product:</i>
                                </b>
                              </h4>
                              <ul>
                                <li>
                                  <span>Posting on DAKSHOW</span>
                                </li>
                                <li>
                                  <span>
                                    DAK Point Rewards for user engagement
                                  </span>
                                </li>
                                <li>
                                  <span>Personal DAKSHOW Profile</span>
                                </li>
                                <li>
                                  <span>One-on-one chat</span>
                                </li>
                                <li>
                                  <span>
                                    One-on-one voice call and video call
                                  </span>
                                </li>
                                <li>
                                  <span>DAKSHOW Treasuries</span>
                                </li>
                                <li>
                                  <span>Release Android app 1.0</span>
                                </li>
                              </ul>

                              <h4>
                                <b>
                                  <i>Marketing Strategy:</i>
                                </b>
                              </h4>
                              <ul>
                                <li>
                                  <span>
                                    Announce policies and invite the community
                                    to test out
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Extend the languages ​​of the application
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Implement the community program for testing
                                    out TestNet
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Launch DAKSHOW Project and "Redeem DAK
                                    Points for Prizes from DAKSHOW treasuries"
                                    Program officially with a target of 10,000
                                    active users
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Pitch DAKSHOW Project at funds and backers
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Connect game makers, KOLs, and KOCs to the
                                    DAK community
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Raise funds and and implement ICO Marketing
                                    for DAK Token
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Release DAK token on Ethereum Mainnet
                                  </span>
                                </li>
                                <li>
                                  <span>Launch Fundraising round</span>
                                </li>
                                <li>
                                  <span>
                                    Implement ICO and DAKSHOW Marketing
                                  </span>
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <img src={Phase2} alt="phase" />
                        </Tooltip>
                      </div>
                      <p className="text">
                        Testing and building Decentralized Internet network
                        infrastructure
                      </p>
                    </div>

                    <div className="content content_up_2">
                      <div className="img">
                        <Tooltip
                          overlayClassName="tooltip"
                          style={{ width: "500px" }}
                          color="#FFDE2E"
                          placement="bottomRight"
                          title={
                            <>
                              <h4>
                                <b>
                                  <i>Release Open Beta:</i>
                                </b>
                              </h4>
                              <h4>
                                <b>
                                  <i>Technology product</i>
                                </b>
                              </h4>
                              <ul>
                                <li>
                                  <span>Swap DP and DT</span>
                                </li>
                                <li>
                                  <span>Bidding</span>
                                </li>
                                <li>
                                  <span>NFT</span>
                                </li>
                                <li>
                                  <span>Group</span>
                                </li>
                                <li>
                                  <span>Private Communication</span>
                                </li>
                                <li>
                                  <span>Cloud VPS</span>
                                </li>
                                <li>
                                  <span>Cloud Storage</span>
                                </li>
                                <li>
                                  <span>Provide API for the community</span>
                                </li>
                              </ul>
                              <h4>
                                <b>
                                  <i>Marketing strategy</i>
                                </b>
                              </h4>
                              <ul>
                                <li>
                                  <span>
                                    Strike up multi-sector collaboration with
                                    international partners
                                  </span>
                                </li>
                                <li>
                                  <span>Host multinational events</span>
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <img src={Phase4} alt="phase" />
                        </Tooltip>
                      </div>
                      <p className="text" style={{ width: "70%" }}>
                        {" "}
                        Expansion of DAK's ecosystem{" "}
                      </p>
                    </div>
                  </div>
                  <div className="line">
                    <div className="img">
                      <img src={Rocket} alt="" />
                    </div>
                  </div>
                  <div className="content_down">
                    <div className="content content_down_1">
                      <div className="img">
                        <Tooltip
                          overlayClassName="tooltip"
                          style={{ width: "500px" }}
                          color="#FFDE2E"
                          placement="rightTop"
                          title={
                            <>
                              <h4>
                                <b>
                                  <i>
                                    Carry out technological research and
                                    research infrastructure
                                  </i>
                                </b>
                              </h4>
                              <ul>
                                <li>
                                  <span>Carry out project analysis</span>
                                </li>
                                <li>
                                  <span>Develop product</span>
                                </li>
                                <li>
                                  <span>Redemption program with DAK POINT</span>
                                </li>
                                <li>
                                  <span>Set up infrastructure</span>
                                </li>
                                <li>
                                  <span>
                                    Set up policies for the DAK community
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Testing application and build community on
                                    DAKSHOW
                                  </span>
                                </li>
                              </ul>
                              {/* <h4>
                                <b>
                                  <i>Release of Close Beta 1.x of DAKSHOW:</i>
                                </b>
                              </h4> */}
                              {/* <p>Issue Token DAK on Ethereum Mainnet:</p> */}
                            </>
                          }
                        >
                          <img src={Phase1} alt="phase" />
                        </Tooltip>
                      </div>
                      <p className="text">Test and build community DAKSHOW</p>
                    </div>
                    <div className="content content_down_2">
                      <div className="img">
                        <Tooltip
                          overlayClassName="tooltip"
                          style={{ width: "500px" }}
                          color="#FFDE2E"
                          placement="leftTop"
                          title={
                            <>
                              <h4>
                                <b>
                                  <i>
                                    Test out Decentralized Cloud Network
                                    Infrastructure:{" "}
                                  </i>
                                </b>
                              </h4>
                              <p>Technology product:</p>
                              <ul>
                                <li>
                                  <span>Release DCC Close Beta 1.0</span>
                                </li>
                                <li>
                                  <span>
                                    Release Close Beta Version 1.x of DAKSHOW
                                  </span>
                                </li>
                                <li>
                                  <span>Launch ICO & DAO</span>
                                </li>
                              </ul>
                              <h4>
                                <b>
                                  <i>Marketing strategy: </i>
                                </b>
                              </h4>
                              <ul>
                                <li>
                                  <span>
                                    Announce policies and invite the community
                                    to join the DCC network
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Launch strategies for expanding into
                                    international market
                                  </span>
                                </li>
                                <li>
                                  <span>Roll out ICO & DAO</span>
                                </li>
                                <li>
                                  <span>List DAK token</span>
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <img src={Phase3} alt="phase" />
                        </Tooltip>
                      </div>
                      <p className="text">ICO Launch and Open Beta Release</p>
                    </div>
                    <div className="content content_down_3">
                      <div className="img">
                        <Tooltip
                          overlayClassName="tooltip"
                          style={{ width: "500px" }}
                          color="#FFDE2E"
                          placement="leftTop"
                          title={
                            <>
                              <h4>
                                <b>
                                  <i>Technology product: </i>
                                </b>
                              </h4>
                              <ul>
                                <li>
                                  <span>Dak Block</span>
                                </li>
                                <li>
                                  <span>AMR </span>
                                </li>
                                <li>
                                  <span>DIMA</span>
                                </li>
                                <li>
                                  <span>GAMEFI</span>
                                </li>
                                <li>
                                  <span>Dmail</span>
                                </li>
                                <li>
                                  <span>Internet ID</span>
                                </li>
                                <li>
                                  <span>Digital signatures</span>
                                </li>
                                <li>
                                  <span>Devbox Monitor</span>
                                </li>
                              </ul>
                              <h4>
                                <b>
                                  <i>Marketing strategy: </i>
                                </b>
                              </h4>
                              <ul>
                                <li>
                                  <span>
                                    Aim at growing into one of the influential
                                    Web3 communities in Asia
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Promote DAO management structure and launch
                                    the AMR, DIMA, GameFi, DAKBLOCK ecosystems
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    Opening up training institutes for KOLs and
                                    professional group leaders
                                  </span>
                                </li>
                              </ul>
                            </>
                          }
                        >
                          <img src={Phase5} alt="phase" />
                        </Tooltip>
                      </div>
                      <p className="text">Deploy the DAK ecosystem expansion</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div ref={wrapRef} className="mobile">
                  <div className="imgRocket">
                    <img src={Rocket} alt="rocket" />
                  </div>
                  <Collapse onChange={onChange} ghost accordion>
                    <Panel
                      header={
                        <div className="title">
                          <div className="img">
                            <img src={Phase5} alt="phase" />
                          </div>
                          <p className="text">
                            Deploy the DAK ecosystem expansion
                          </p>
                        </div>
                      }
                      key="5"
                    >
                      <div className="content">
                        <h4>
                          <b>
                            <i>Technology product: </i>
                          </b>
                        </h4>
                        <ul>
                          <li>
                            <span>Dak Block</span>
                          </li>
                          <li>
                            <span>AMR </span>
                          </li>
                          <li>
                            <span>DIMA</span>
                          </li>
                          <li>
                            <span>GAMEFI</span>
                          </li>
                          <li>
                            <span>Dmail</span>
                          </li>
                          <li>
                            <span>Internet ID</span>
                          </li>
                          <li>
                            <span>Digital signatures</span>
                          </li>
                          <li>
                            <span>Devbox Monitor</span>
                          </li>
                        </ul>
                        <h4>
                          <b>
                            <i>Marketing strategy: </i>
                          </b>
                        </h4>
                        <ul>
                          <li>
                            <span>
                              Aim at growing into one of the influential Web3
                              communities in Asia
                            </span>
                          </li>
                          <li>
                            <span>
                              Promote DAO management structure and launch the
                              AMR, DIMA, GameFi, DAKBLOCK ecosystems
                            </span>
                          </li>
                          <li>
                            <span>
                              Opening up training institutes for KOLs and
                              professional group leaders
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Panel>
                    <Panel
                      id="#comeHere"
                      header={
                        <div
                          ref={myElementRef}
                          onClick={() => handleOnClick(4)}
                          className="title"
                        >
                          <div className="img">
                            <img src={Phase4} alt="phase" />
                          </div>
                          <p className="text">Expansion of DAK's ecosystem.</p>
                        </div>
                      }
                      key="4"
                    >
                      <div className="content">
                        <h4>
                          <b>
                            <i>
                              Release Open Beta: <br />
                              Technology product
                            </i>
                          </b>
                        </h4>
                        <ul>
                          <li>
                            <span>Swap DP and DT</span>
                          </li>
                          <li>
                            <span>Bidding</span>
                          </li>
                          <li>
                            <span>NFT</span>
                          </li>
                          <li>
                            <span>Group</span>
                          </li>
                          <li>
                            <span>Private Communication</span>
                          </li>
                          <li>
                            <span>Cloud VPS</span>
                          </li>
                          <li>
                            <span>Cloud Storage</span>
                          </li>
                          <li>
                            <span>Provide API for the community</span>
                          </li>
                        </ul>
                        <h4>
                          <b>
                            <i>Marketing strategy</i>
                          </b>
                        </h4>
                        <ul>
                          <li>
                            <span>
                              Strike up multi-sector collaboration with
                              international partners
                            </span>
                          </li>
                          <li>
                            <span>Host multinational events</span>
                          </li>
                        </ul>
                      </div>
                    </Panel>
                    <Panel
                      header={
                        <div
                          ref={myElementRef2}
                          onClick={() => handleOnClick(3)}
                          className="title"
                        >
                          <div className="img">
                            <img src={Phase3} alt="phase" />
                          </div>
                          <p className="text">
                            ICO Launch and Open Beta Release.
                          </p>
                        </div>
                      }
                      key="3"
                    >
                      <div className="content">
                        <h4>
                          <b>
                            <i>
                              Test out Decentralized Cloud Network
                              Infrastructure: <br /> Technology product:
                            </i>
                          </b>
                        </h4>
                        <ul>
                          <li>
                            <span>Release DCC Close Beta 1.0</span>
                          </li>
                          <li>
                            <span>
                              Release Close Beta Version 1.x of DAKSHOW
                            </span>
                          </li>
                          <li>
                            <span>Launch ICO & DAO</span>
                          </li>
                        </ul>
                        <h4>
                          <b>
                            <i>Marketing strategy: </i>
                          </b>
                        </h4>
                        <ul>
                          <li>
                            <span>
                              Announce policies and invite the community to join
                              the DCC network
                            </span>
                          </li>
                          <li>
                            <span>
                              Launch strategies for expanding into international
                              market{" "}
                            </span>
                          </li>
                          <li>
                            <span>Roll out ICO & DAO </span>
                          </li>
                          <li>
                            <span>List DAK token</span>
                          </li>
                        </ul>
                      </div>
                    </Panel>
                    <Panel
                      header={
                        <div className="title">
                          <div className="img">
                            <img src={Phase2} alt="phase" />
                          </div>
                          <p className="text">
                            Testing and building Decentralized Internet network
                            infrastructure.
                          </p>
                        </div>
                      }
                      key="2"
                    >
                      <div className="content">
                        <h4>
                          <b>
                            <i>
                              Test out and build the DAK community <br />
                              Technology product:
                            </i>
                          </b>
                        </h4>
                        <ul>
                          <li>
                            <span>Posting on DAKSHOW</span>
                          </li>
                          <li>
                            <span>DAK Point Rewards for user engagement</span>
                          </li>
                          <li>
                            <span>Personal DAKSHOW Profile</span>
                          </li>
                          <li>
                            <span>One-on-one chat</span>
                          </li>
                          <li>
                            <span>One-on-one voice call and video call</span>
                          </li>
                          <li>
                            <span>DAKSHOW Treasuries</span>
                          </li>
                          <li>
                            <span>Release Android app 1.0</span>
                          </li>
                        </ul>

                        <h4>
                          <b>
                            <i>Marketing Strategy:</i>
                          </b>
                        </h4>
                        <ul>
                          <li>
                            <span>
                              Announce policies and invite the community to test
                              out
                            </span>
                          </li>
                          <li>
                            <span>
                              Extend the languages ​​of the application
                            </span>
                          </li>
                          <li>
                            <span>
                              Implement the community program for testing out
                              TestNet
                            </span>
                          </li>
                          <li>
                            <span>
                              Launch DAKSHOW Project and "Redeem DAK Points for
                              Prizes from DAKSHOW treasuries" Program officially
                              with a target of 10,000 active users
                            </span>
                          </li>
                          <li>
                            <span>
                              Pitch DAKSHOW Project at funds and backers
                            </span>
                          </li>
                          <li>
                            <span>
                              Connect game makers, KOLs, and KOCs to the DAK
                              community
                            </span>
                          </li>
                          <li>
                            <span>
                              Raise funds and and implement ICO Marketing for
                              DAK Token
                            </span>
                          </li>
                          <li>
                            <span>Release DAK token on Ethereum Mainnet</span>
                          </li>
                          <li>
                            <span>Launch Fundraising round</span>
                          </li>
                          <li>
                            <span>Implement ICO and DAKSHOW Marketing</span>
                          </li>
                        </ul>
                      </div>
                    </Panel>
                    <Panel
                      header={
                        <div className="title">
                          <div className="img">
                            <img src={Phase1} alt="phase" />
                          </div>
                          <p className="text">
                            Test and build community DAKSHOW.
                          </p>
                        </div>
                      }
                      key="1"
                    >
                      <div className="content">
                        <h4>
                          <b>
                            <i>
                              Carry out technological research and research
                              infrastructure:{" "}
                            </i>
                          </b>
                        </h4>
                        <ul>
                          <li>
                            <span>Carry out project analysis</span>
                          </li>
                          <li>
                            <span>Develop product</span>
                          </li>
                          <li>
                            <span>Redemption program with DAK POINT</span>
                          </li>
                          <li>
                            <span>Set up infrastructure</span>
                          </li>
                          <li>
                            <span>Set up policies for the DAK community</span>
                          </li>
                          <li>
                            <span>
                              Testing application and build community on DAKSHOW
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              )}
            </div>
          </SwiperSlide>
        </Swiper>
        <Footer />
      </div>
    </>
  );
};

export default Roadmap;
