import "./Team.scss";
import { Button, Modal } from "antd";
import { useState } from "react";

import DKH from "../../../../assets/images/DKH.jpg";
import VDA from "../../../../assets/images/VDA.jpg";
import HA from "../../../../assets/images/HA.jpg";
import NA from "../../../../assets/images/z4180807412698_d9bf87bbd213b9de3fb9c56efdc97c48.jpg";
import DU from "../../../../assets/images/z4180807604764_051b8c614b44e883e0089b45c62c9200.jpg";
import CONG from "../../../../assets/images/CONG.jpg";
import HUNG from "../../../../assets/images/HUNG.jpg";
import NGUYEN from "../../../../assets/images/NGUYEN.jpg";
import LUAN from "../../../../assets/images/luan.jpg";
import THINHBAO from "../../../../assets/images/THINH.jpg";
import QUANG from "../../../../assets/images/quang.jpg";
import KHOA from "../../../../assets/images/KHOA.jpg";

// mobile


export default function Team() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLayout, setModalLayout] = useState();
  const showModal = (num) => {
    setIsModalOpen(true);
    switch (num) {
      case 1:
        return setModalLayout(
          <div className="modal-container">
            <div className="modal-img">
              <img src={DKH} alt="avatar" />
            </div>
            <div className="modal-contents">
              <p>PhD</p>
              <h1>DANG KHANH HUNG</h1>
              <p>Research Fellow - NUS University - Singapore</p>
              <p>
                Director of the Institute for Research & Innovation in Computer
                Science - IRICS
              </p>
              <a href="https://www.linkedin.com/in/dkhme/"  target="_blank">Connect</a>
            </div>
          </div>
        );
      case 2:
        return setModalLayout(
          <div className="modal-container">
            <div className="modal-img">
              <img src={VDA} alt="avatar" />
            </div>
            <div className="modal-contents">
              <p>PhD</p>
              <h1>VU DUY ANH</h1>
              <p>
                Director of Consulting - Marketing on Global Digital
                Transformation - FPT Software, Japan
              </p>
              <p>
                Director of Blockchain Excellence Center - FPT Software, Japan
              </p>
            </div>
          </div>
        );
      case 3:
        return setModalLayout(
          <div className="modal-container">
            <div className="modal-img">
              <img src={HA} alt="avatar" />
            </div>
            <div className="modal-contents">
              <p>CEO</p>
              <h1>NGUYEN TAN HUNG ANH</h1>
              <a href="https://www.linkedin.com/in/nguyen-tan-hung-anh-b56541304" target="_blank" >Connect</a>
            </div>
          </div>
        );
      case 5:
        return setModalLayout(
          <div className="modal-container">
            <div className="modal-img">
              <img src={DU} alt="avatar" />
            </div>
            <div className="modal-contents">
              <p>CDO</p>
              <h1>PHAN TRUONG DU</h1>
              <a href="https://www.linkedin.com/in/daniel-phan-47151993/" target="_blank" >Connect</a>
            </div>
          </div>
        );
      case 6:
        return setModalLayout(
          <div className="modal-container">
            <div className="modal-img">
              <img src={HUNG} alt="avatar" />
            </div>
            <div className="modal-contents">
              <p>LEADER BACK-END</p>
              <h1>VU THANH HUNG</h1>
              <p placeholder="email" >Email: hung2811.iuh@gmail.com</p>
            </div>
          </div>
        );
      case 8:
        return setModalLayout(
          <div className="modal-container">
            <div className="modal-img">
              <img src={LUAN} alt="avatar" />
            </div>
            <div className="modal-contents">
              <p>CLOUD & SYSTEM ADMIN</p>
              <h1>VO THANH LUAN</h1>
              <p placeholder="email" >Email: luan.vo290700@stechvn.org</p>
            </div>
          </div>
        );
      case 9:
        return setModalLayout(
          <div className="modal-container">
            <div className="modal-img">
              <img src={THINHBAO} alt="avatar" />
            </div>
            <div className="modal-contents">
              <p>SEC & SYSTEM ADMIN</p>
              <h1>LE HA QUANG THINH</h1>
              <a href="https://www.facebook.com/le.thinh.uit" target="_blank" >Connect</a>
            </div>
          </div>
        );
      // case 10:
      //     return setModalLayout(
      //         <div className='modal-container'>
      //             <div className='modal-img'>
      //                 <img src={QUANG} alt="avatar" />
      //             </div>
      //             <div className='modal-contents'>
      //                 <p>BLOCKCHAIN DEVELOPER</p>
      //                 <h1>VO HONG QUANG</h1>
      //             </div>
      //         </div>
      // )
      case 11:
        return setModalLayout(
          <div className="modal-container">
            <div className="modal-img">
              <img src={KHOA} alt="avatar" />
            </div>
            <div className="modal-contents">
              <p>BLOCKCHAIN DEVELOPER</p>
              <h1>NGUYEN LE ANH KHOA</h1>
              <a href="https://www.linkedin.com/in/nguyen-le-anh-khoa-940a77181/" target="_blank" >Connect</a>
            </div>
          </div>
        );
        case 12:
          return setModalLayout(
            <div className="modal-container">
              <div className="modal-img">
                <img src={CONG} alt="avatar" />
              </div>
              <div className="modal-contents">
                <p>CMO</p>
                <h1>NGUYEN THANH CONG</h1>
                <a href="https://www.linkedin.com/in/ethan-nguyen-55b7a8256/" target="_blank" >Connect</a>
              </div>
            </div>
          );
      default:
        break;
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="team-container">
      <Modal
        bodyStyle={{
          backgroundColor: "#000",
          margin: "-24px",
          padding: "24px",
          borderRadius: "10px",
        }}
        centered
        width={800}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {modalLayout}
      </Modal>

      {/* Row 1 */}
      <div className={"team-introduce"}>
        <h1>OUR TEAM</h1>
        <p>
          DAK's team consists of entrepreneurs, programmers, and a board of
          advisors with extensive experience. Our core members have spent the
          last 10 years developing technology and startups.
        </p>
      </div>

      {/* Row 2 */}
      <div className={"team-founder"}>
        <h1>CORE FOUNDER</h1>
        <div className={"founders"}>
          <div onClick={() => showModal(3)} data-aos="fade-up">
            <div>
              <img src={HA} alt="avatar" />
              <button>CEO</button>
            </div>
            <p>NGUYEN TAN HUNG ANH</p>
          </div>
          <div
            onClick={() => showModal(5)}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div>
              <img src={DU} alt="avatar" />
              <button>CDO</button>
            </div>
            <p>PHAN TRUONG DU</p>
          </div>
          <div
            onClick={() => showModal(12)}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div>
              <img src={CONG} alt="avatar" />
              <button>CMO </button>
            </div>
            <p>NGUYEN THANH CONG</p>
          </div>
        </div>
      </div>

      {/* Row 3 */}
      <div className={"team-developer"}>
        <h1>DEVELOPMENT TEAM</h1>
        <div className={"developers"}>
          <div onClick={() => showModal(6)} data-aos="fade-up">
            <div>
              <img src={HUNG} alt="avatar" />
              <button>LEADER BACK-END</button>
            </div>
            <p>VU THANH HUNG</p>
          </div>
          <div
            onClick={() => showModal(8)}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div>
              <img src={LUAN} alt="avatar" />
              <button>CLOUD & SYSTEM ADMIN</button>
            </div>
            <p>VO THANH LUAN</p>
          </div>
          <div onClick={() => showModal(9)} data-aos="fade-up">
            <div>
              <img src={THINHBAO} alt="avatar" />
              <button>SEC & SYSTEM ADMIN</button>
            </div>
            <p>LE HA QUANG THINH</p>
          </div>
          <div
            onClick={() => showModal(11)}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div>
              <img src={KHOA} alt="avatar" />
              <button>BLOCKCHAIN DEVELOPER</button>
            </div>
            <p>NGUYEN LE ANH KHOA</p>
          </div>
        </div>
      </div>

      {/* Row 4 */}
      <div className={"team-advisor"}>
        <h1>Advisor</h1>
        <div className={"advisors"}>
          <div onClick={() => showModal(1)} data-aos="fade-up">
            <div>
              <img src={DKH} alt="avatar" />
              <button>PhD</button>
            </div>
            <p>DANG KHANH HUNG</p>
          </div>
        </div>
      </div>

      {/* Row 5 */}
      {/* <div className={'team-mobile'}>
                <h1>MOBILE DEVELOPMENT</h1>
                <div className={'mobiles'}>
                    <div
                        data-aos="fade-up">
                        <img src={LUANMOBILE} alt="avatar" />
                        <button>LEADER MOBILE DEVELOPER</button>
                        <p>NGUYEN THANH LUAN</p>
                    </div>
                    <div
                        data-aos="fade-up"
                        data-aos-delay="100">
                        <img src={MANHMOBILE} alt="avatar" />
                        <button>MOBILE DEVELOPER</button>
                        <p>LE VAN MANH</p>
                    </div>
                </div>
            </div> */}
    </div>
  );
}
