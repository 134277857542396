import React from "react";
import "./DakPoint.scss";
import DP from "../../../../../assets/images/dpedit.png";
import DP1 from "../../../../../assets/images/dp 1.png";
import DP2 from "../../../../../assets/images/dp 2.png";
import DP3 from "../../../../../assets/images/dp 3.png";
import DP4 from "../../../../../assets/images/dp 4.png";
import DP5 from "../../../../../assets/images/dp5.png";
import DP6 from "../../../../../assets/images/dp6.png";

const DakPoint = () => {
  return (
    <div className="tokenomic_dakpoint">
      <div className="tokenomic_dakpoint_up">
        <div
          data-aos="fade-down"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          className="tokenomic_dakpoint_up_left"
        >
          <img src={DP} alt="Dak Point" />
        </div>
        <div
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1500"
          className="tokenomic_dakpoint_up_right"
        >
          <div className="content">
            <img src={DP1} alt="Dak Point" />
          </div>
          <div className="content">
            <img src={DP2} alt="Dak Point" />
          </div>
          <div className="content">
            <img src={DP3} alt="Dak Point" />
          </div>
          <div className="content">
            <img src={DP4} alt="Dak Point" />
          </div>
        </div>
      </div>

      <div
        data-aos="fade-down"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1500"
        className="tokenomic_dakpoint_down"
      >
        <div className="tokenomic_dakpoint_down_left">
          <img src={DP5} alt="Dak Point" />
        </div>
        <div className="tokenomic_dakpoint_down_right">
          <div className="content">
            <img src={DP6} alt="Dak Point" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DakPoint;
