import { useEffect, useState } from "react";
import Headers from "./Header";
import { PageContainer } from "@ant-design/pro-components";
import RouterItem from "../RouterItem/RouterItem";
import { Layout } from "antd";
import "./Dashboard.scss";

const { Header } = Layout;
const Dashboard = () => {
  const [header, setHeader] = useState(false);
  const changeLayoutHeader = () => {
    if (window.scrollY > 10) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeLayoutHeader);

    return () => window.removeEventListener("scroll", changeLayoutHeader);
  }, []);

  return (
    <>
      <header
        style={{
          background: header ? "rgb(0, 0, 0, 0.9)" : "none",
        }}
        className="header"
      >
        <Headers />
      </header>
      <RouterItem />
    </>
  );
};

export default Dashboard;
