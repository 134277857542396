export const vn = {
  HOME: "Trang chủ",
  ABOUT_US: "Về chúng tôi",
};

export const en = {
  HOME: "Home",
  ABOUT_US: "About Us",
  ABOUT_DAK: "About DAK",
  VIMIVAL: "Vision - Mission - Value",
  ACTIVITY: "Activity",
  ECOSYSTEM: "Ecosystem",
  ECOSYSTEM_OVERVIEW: "Overview",
  ECOSYSTEM_DAKSHOW: "DAKShow",
  ECOSYSTEM_DAKBLOCK_DAKCLOUD: "DCC & DAK Block",
  ECOSYSTEM_AI: "AI",
  ECOSYSTEM_GAME_FI: "Game FI",
  TOKENOMIC: "Tokenomic",
  TEAM: "Team",
  BACKER: "Backers",
  ROADMAP: "Roadmap",
};
