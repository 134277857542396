import "./AboutUs.scss"
import about from '../../../../assets/images/aboutusuk.jpg'
import HungAnh from '../../../../assets/images/ANH.jpg'
import CAnh from '../../../../assets/images/NA.jpg'
import Du from '../../../../assets/images/DU.jpg'
import Nguyen from '../../../../assets/images/NGUYEN.jpg'
import Hung from '../../../../assets/images/HUNG.jpg'
import Luan from '../../../../assets/images/luan.jpg'
import Thinh from '../../../../assets/images/thinhBao.jpg'
import Quang from '../../../../assets/images/quang.jpg'
import Khoa from '../../../../assets/images/z3903694458488_f97544c17fff7131ccbdefc7fc0ddd77.jpg'
import { useState } from "react"
import { en, vn } from "./language"

const AboutUs = () => {
    const language = localStorage.getItem("language") || 'en'

    const [content] = useState(language === 'vn' ? vn : en);
    const teamMember = [
        {
            name: 'NGUYEN TAN HUNG ANH',
            position: 'FOUNDERS & CEO',
            className: 'tech_team_content_1',
            avatar: HungAnh
        },
        {
            name: 'VU THANH HUNG',
            position: 'Co-FOUNDERS & Lead Developer',
            className: 'tech_team_content_3',
            avatar: Hung
        },
        {
            name: 'NGUYEN THANH CONG',
            position: 'CMO',
            className: 'tech_team_content_2',
            avatar: HungAnh
        },
        {
            name: 'VO THANH LUAN',
            position: 'Backend Developer',
            className: 'tech_team_content_4',
            avatar: Luan
        },
        {
            name: 'LE HA QUANG THINH',
            position: 'Security & System Adminstrator',
            className: 'tech_team_content_5',
            avatar: Thinh
        },
        {
            name: 'PHAM HONG QUANG',
            position: 'Blockchain Developer',
            className: 'tech_team_content_6',
            avatar: Quang
        },
        {
            name: 'NGUYEN LE ANH KHOA',
            position: 'Blockchain Developer',
            className: 'tech_team_content_7',
            avatar: Khoa
        },
        {
            name: 'PHAN TRUONG DU',
            position: 'Co-FOUNDERS & CDO',
            className: 'tech_team_content_9',
            avatar: Du
        },

    ]

    return (
        <>
            <div className="introduce" 
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-anchor-placement="top-bottom"
            >
                <img loading="lazy" style={{objectFit: 'cover'}} src={about} alt="logo"/>
                <h3>{content.BANNER}</h3>
            </div>
            <div className="introduce_container">
                <div className="contentIntroduce">
                    <div className="main-line-1">
                        <div className="line-1" data-aos="fade-right" data-aos-duration="2000">
                            <h3>{content.FIRST_CONTENT.title}</h3>
                        </div>
                        <div className="line-2" data-aos="fade-left" data-aos-duration="2000">
                            <span>
                                {content.FIRST_CONTENT.desc1}
                            </span>
                        </div>
                    </div>

                    <div className="first-content">
                        <div className="line-3" data-aos="fade-right" data-aos-duration="2000">
                            <span>
                                {content.FIRST_CONTENT.desc2}
                            </span>
                        </div>
                        <div className="line-4" data-aos="fade-left" data-aos-duration="2000">
                            <span>
                                {content.FIRST_CONTENT.desc3}
                            </span>
                        </div>
                        <div className="line-5" data-aos="zoom-in" data-aos-duration="1000">
                            <img loading="lazy" src={require("../../../../assets/images/company1.png")} alt="company1"/>
                        </div>
                        <div className="line-6" data-aos="zoom-in" data-aos-duration="1000">
                            <img loading="lazy" src={require("../../../../assets/images/company2.png")} alt="company2"/>
                        </div>
                    </div>

                    <div className="second-content">
                        <div className="content-value">
                            <div className="content" data-aos="fade-right" data-aos-duration="2000">
                                <h3>{content.SECOND_CONTENT.title1}</h3>
                                <p>
                                    {content.SECOND_CONTENT.desc1}
                                </p>
                            </div>
                            <div className="img" data-aos="zoom-in" data-aos-duration="1000">
                                <img loading="lazy" src={require("../../../../assets/images/company3.jpg")} alt="company1"/>
                            </div>
                        </div>
                        <div className="content-for-customer">
                            <div className="img" data-aos="zoom-in" data-aos-duration="1000">
                                <img loading="lazy" src={require("../../../../assets/images/company4.jpg")} alt="company1"/>
                            </div>
                            <div className="content" data-aos="fade-left" data-aos-duration="2000">
                                <h3>{content.SECOND_CONTENT.title2}</h3>
                                <p>
                                    {content.SECOND_CONTENT.desc2}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="ourTeam">
                    <h2 data-aos="zoom-in" data-aos-duration="1500">{content.THIRD_CONTENT.title1}</h2>
                </div>
                <div className="staff" data-aos="zoom-in" data-aos-duration="2000">
                    <img loading="lazy" src={require("../../../../assets/images/staff.png")} alt="logo"/>
                </div>

                <div className="tech_team">
                    <h2>{content.THIRD_CONTENT.title2}</h2>
                    <div className="tech_team_content">
                       {
                        teamMember.map((item) => (
                            <div className={`tech_team_content_detail ${item.className}`}>
                                <div data-aos="flip-left" data-aos-duration="5000" className="img">
                                    <img loading="lazy" src={item.avatar} alt="team member" />
                                </div>
                                <div data-aos="zoom-in" data-aos-duration="1000" className="text">
                                    <h3> {item.name} </h3>
                                    <span> {item.position} </span>
                                </div>
                            </div>
                        ))
                       }

                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs